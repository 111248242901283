@import '../variables';

.wm-update-body{
  @include respond-to('x-small') {
      padding: 0px 5px;
    }

    @include respond-to('small') {
      padding: 0px 5px;
    }
  
    @include respond-to('medium') {
      padding: 0px 5px;
    }
  
    @include respond-to('large') {
      padding: 0px 15px;
    }

  
  .mmc_flex__item{

      @include respond-to('x-small') {
          padding: 12px 0;
          flex-direction: column;
        }
  
        @include respond-to('small') {
          padding: 12px 0;
        }
      
        @include respond-to('medium') {
          padding: 12px 0;
        }
      
        @include respond-to('large') {
          display: flex;
          flex-direction: inherit;
          padding: 20px 0;
        }
      .wm-middle-set{
          flex: 50% 0;
          display: flex;
          flex-direction: column;
          line-height: 1.5;
          .wm-one-mattress{
              @include respond-to('x-small') {
                  font-size: 18px;
                }
          
                @include respond-to('small') {
                  font-size: 18px;
                }
              
                @include respond-to('medium') {
                  font-size: 18px;
                }
              
                @include respond-to('large') {
                  font-size: 20px;
                }

          }
      }
      display: flex; 
      padding: 12px 0; 
      .wm-update-left{
          flex: 50% 0;
          display: flex;
          .wm-update-corect-icon{
              .check-circle-size{

                  @include respond-to('x-small') {
                      font-size: 31px;
                    }
              
                    @include respond-to('small') {
                      font-size: 31px;
                    }
                  
                    @include respond-to('medium') {
                      font-size: 31px;
                    }
                  
                    @include respond-to('large') {
                      font-size: 38px;
                    }

              }
          }
          .wm-your-waste{

              @include respond-to('x-small') {
                  font-size: 21px;
                }
          
                @include respond-to('small') {
                  font-size: 21px;
                }
              
                @include respond-to('medium') {
                  font-size: 21px;
                }
              
                @include respond-to('large') {
                  font-size: 25px;
                  padding-left: 15px;
                }

          }
      }
      .wm-update-right{

          @include respond-to('x-small') {
              padding-top: 15px;
              justify-content: flex-start;
            }
      
            @include respond-to('small') {
              padding-top: 15px;
              justify-content: flex-start;
            }
          
            @include respond-to('medium') {
              padding-top: 15px;
            }
          
            @include respond-to('large') {
              display: flex;
              flex: 50% 0;
              padding-top: 0px;
              justify-content: flex-end;
              // padding-left: 233px;
            }

          .wm-print-icon{
              .print-size{
                  @include respond-to('x-small') {
                      font-size: 31px;
                    }
              
                    @include respond-to('small') {
                      font-size: 31px;
                    }
                  
                    @include respond-to('medium') {
                      font-size: 31px;
                    }
                  
                    @include respond-to('large') {
                      font-size: 38px;
                      // padding-left: 232px;
                    }
              }

          }
          .wm-card-title{
              @include respond-to('x-small') {
                  padding-left: 10px;
                }
          
                @include respond-to('small') {
                  padding-left: 10px;
                }
              
                @include respond-to('medium') {
                  padding-left: 10px;
                }
              
                @include respond-to('large') {
                  padding-top: 1px;
                }

          }
      }
      
  }
  .wm-update-mat-card{
      box-shadow: none;
      background: #d2b996 !important;
      width: 100%;
      .wm-update-card-padding{
          padding: 10px 10px;
          .wm-wb-two-four{
              font-size: 25px;
              font-weight: 500;
          }
          .wm-waste-bookin{

          }
      }
  }
}


.wm-card-focus{
  width: 100%;
  padding: 25px 0px;
}

.wm-card-black{
  .wm-card-description{
      color: white;
      background: black;
      padding: 10px 19px;
      
  }

}
