$wm-text--color : $font-color;
$wm-border--color : $opt-border-color;

.modal-width,.custom-dialog-container{

    @include respond-to("x-small") {
              max-width: 90% !important;
               width: 90% !important;
               overflow-y: hidden;
            }
          @include respond-to("small") {
                 max-width: 90% !important;
                  width: 90% !important;
                  overflow-y: hidden;
            }
            @include respond-to("medium") {
                 max-width: 90% !important;
                 width: 90% !important;
                 overflow-y: hidden;
            }
            @include respond-to("large") {
                 max-width: 90% !important;
                 width: 90% !important;
                 overflow-y: hidden;
            }
            @include respond-to("x-large") {
                 max-width: 60% !important;
                  width: 60% !important;
                  overflow-y: hidden;
            }
}

.wm {
    &_container--inner {

        @include respond-to('x-small') {
            height: auto !important;             
        }
                                
        @include respond-to('small') {
            height: auto !important;                
        }
                            
        @include respond-to('medium') {
            height: auto !important;
        }
                            
        @include respond-to('large') {
            height: auto !important;
        }   

        @include respond-to('x-large') {
            height: calc(100vh - 526px) !important;
            background-color: #f5f5f5 !important;
        } 
    }

    &-btn-type-main {
        background-color: $main-btn-color;
    }

    &__header {
        font-size: 25px !important;
    }

    &-sub_header {
        font-size: 15px;
        padding: 15px 0px !important;
    }

    &-btn--cage {        
        display: flex;
        justify-content: center;
        margin-top: 15px !important;

        .wm-btn__cage{
            display: flex;
            align-items: center;
            width: 250px !important;
        }
    }

    &-btn__cage-right{
        width: 250px !important;
        display: flex !important;
        align-items: center !important;
    }

    &-col-left {
        padding: 20px !important;

        @include respond-to('x-small') {
            flex: 100% 0 0;
            border: none;
            padding-bottom: 20px;               
        }
                                
        @include respond-to('small') {
            flex: 100% 0 0;
            border-bottom: 1px solid $wm-border--color;
            padding-bottom: 20px;                 
        }
                            
        @include respond-to('medium') {
            flex: 100% 0 0;
            border: none;
            padding-bottom: 20px;
        }
                            
        @include respond-to('large') {
            flex: 50% 0 1 !important;
            border-bottom: none;
            border-right: 1px solid $wm-border--color;
            padding-bottom: 0px;
        }    
    }

    &-col-right {
        padding: 20px !important;

        @include respond-to('x-small') {
            flex: 100% 0 0 !important;        
        }

        @include respond-to('small') {
            flex: 100% 0 0 !important;        
        }
        
        @include respond-to('medium') {
            flex: 100% 0 0 !important;
        }
        
        @include respond-to('large') {
            flex: 50% 0 1 !important;
        }
    } 

    &-search-cage {
        text-align: center;
        margin-top: 18px;
        margin: 30px 10px;

        .wm-booking-search{
            width: 80% !important; 
            background-color: white !important;

            .mat-form-field-wrapper {
              margin: 0 !important;
              padding-bottom: 0 !important;

              .mat-form-field-flex {
                padding: 15px 9px !important;

                .mat-form-field-infix {
                  padding: 0 !important;
                  border-top: 0 !important;
              
                }
                
              }

            }
        }
    }

    &_container--inner {

        .wm-two-coll {

            .mmc_flex--container {

                @include respond-to('x-small') {        
                    flex-direction: column;
                  }

                  @include respond-to('small') {                
                    flex-direction: column;
                  }
                
                  @include respond-to('medium') {
                    flex-direction: column;
                  }
                
                  @include respond-to('large') {
                    flex-direction: row;
                    padding: 0 !important;
                  }
            }
        }
    }

    &-modals-container {

        @include respond-to('x-small') {
            width: 300px;
        }

        @include respond-to('small') {
            width: 300px;
        }
        
        @include respond-to('medium') {
            width: 800px;
        }
        
        @include respond-to('large') {
            width: 800px;
        }

          .mmc-modal-header {
            z-index: 111;
            height: 70px;
            @include box_shadow (1);
            // box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
            position: relative;

            .wm-modal-title {
                @include respond-to('x-small') {
                    font-size: 13px;
                }
        
                @include respond-to('small') {
                    font-size: 13px;
                }
                
                @include respond-to('medium') {
                    font-size: 16px;
                }
                
                @include respond-to('large') {
                    padding-left: 24px;
                    font-weight: 600;
                    font-size: 19px;
                }
                

            }

            .wm-modal-subTitle {
                color: $confirmed-status;
                font-weight: 600;
                font-size: 15px;
            }

            .wm-modal-subTitle-free {
                font-weight: 600;
                font-size: 15px;
                color: green;

            }

            .icon-cage {
                width: 70px;
                color: #fff;
                background-color: #fff;
                margin-right: 15px;
            }

            .close-icon-cage {
                width: 70px;
                color: #3a424d;
            }
          }

          .mmc-modal-body {
            padding: 16px 20px !important;
            max-height: 70vh;
            overflow-y: scroll;
          }

          .mmc-modal-footer {
            z-index: 111;
            justify-content: center;
            align-items: center;
            height: 65px !important;
            @include box_shadow (2);
            // box-shadow: 0 1px 8px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 24%);
            position: relative;

            .wm_btn_cage {

                  .wm-modal-btn {

                    @include respond-to('x-small') {
                        width: 120px !important;
                        margin-right: 20px !important;                    
                      }

                      @include respond-to('small') {
                        width: 120px !important;
                        margin-right: 20px !important;       
                      }
                    
                      @include respond-to('medium') {
                        width: 150px !important;
                        margin-right: 20px !important;
                      }
                    
                      @include respond-to('large') {
                        width: 150px !important;
                        margin-right: 20px !important;
                      }
                  }
            }
          }
    }

    &-two-coll {
        background-color: #f5f5f5 !important;

        .mmc_flex--container {

            .mmc_flex__item {

                .mat-horizontal-stepper-header-container {
                    background-color: #f5f5f5 !important;
                }

                .mat-horizontal-content-container {
                    background-color: #f5f5f5 !important;
                }
            }
        }
    }
}

.header__user{
    margin-bottom:16px;
}
@media screen and (max-width: 768px) {
    .header__user{
        height: auto !important;
        margin-bottom: 0px !important; 
        .navbar-collapse{
            margin:0px !important; 
        }
        .user{
            height: auto !important;
            width: 100%;
            padding-bottom: 0px !important;
        }
    }

    .waste-header-flex {
        display: flex;
        align-items: flex-start !important;
        flex-direction: column;

        div:nth-child(2){
            padding-top: 10px;
            margin-top: -6px !important;
        }
        div:nth-child(1){
            padding-top: 10px;
        }
    }
        
}
.waste-header-flex {
    display: flex;
    align-items: center;

    div:nth-child(2){
        margin-top: 0px;
    }
}

.mmc-btn-secondary--small {
    border: 1px solid #d2b996 !important;
    background-color: #fff !important;
    color: #000000 !important;
}

.mmc-btn-secondary--small:hover {
    color: #000 !important;
    background-color: #d2b996 !important;
    border: 1px solid #d2b996 !important;
}

.wm-modal-header {
    @include respond-to('x-small') {
        height: 85px !important;                   
      }

      @include respond-to('small') {
        height: 85px !important;      
      }
    
      @include respond-to('medium') {
        height: 70px !important;
      }
    
      @include respond-to('large') {
        height: 70px !important;
      }
    
}

.header-stepper {
    flex: 0 0 100% !important;
  }

.wm-term-pop {
    @include respond-to('x-small') {
        width: 100% !important;
    }

    @include respond-to('small') {
        width: 100% !important;
    }
    
    @include respond-to('medium') {
        width: 100% !important;
    }
    
    @include respond-to('large') {
        width: 100% !important;
    }

}

.advanced-search-click {
    padding: 16px;
    position: sticky;
    bottom: 0;
    background: white;
    border-top: 1px solid #babbab;
    a{
        color: rgb(160, 116, 69) !important;
        font-size: 14px !important;
    }
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0 !important;
}