@import "../variables.scss";

.wm-term-header {
    @include respond-to('x-small') {
        font-size: 21px;
      }
    
      @include respond-to('small') {
        font-size: 21px;
      }
    
      @include respond-to('medium') {
        font-size: 25px;
      }
    
      @include respond-to('large') {
        font-size: 25px;
        font-weight: 600;
        text-decoration: underline;
      }
    
}

.wm-term-body {
    padding: 15px 0;
}

.wm-term-text {
    display: flex;
}

.term-tick {
    margin-right: 10px;
}

.wm-term-checkbox-tick {
    padding-top: 15px;
}

.wm-term-image {
    text-align: center;
    padding: 10px 0px;
}

.term-middle-image {
    @include respond-to('x-small') {
        width: 80%;
        // max-width: 300px !important;
        object-fit: cover !important;
      }
    
      @include respond-to('small') {
        width: 80%;
        // max-width: 300px !important;
        object-fit: cover !important;
      }
    
      @include respond-to('medium') {
        width: 60%;
      }
    
      @include respond-to('large') {
        width: 40%;
      }

}

.mmc-modal-footer{
    border-top: 1px solid #989696;
    padding: 10px;
    margin-top: 0px;
    .wm-term-btn {
      width: 100px !important;
      margin-right: 10px !important;
      font-size: 0.875rem !important;
      font-weight: 500 !important;
      height: 40px !important;
  }
}

.wm-term-checkbox-tick {
    .mat-checkbox-inner-container {
        @include respond-to('x-small') {
            top: 4px !important;
          }
        
          @include respond-to('small') {
            top: 4px !important;
          }
        
          @include respond-to('medium') {
            top: 2px !important;
          }
        
          @include respond-to('large') {
            top: 2px !important;
          }
        
    }

    .mat-checkbox-label {
        @include respond-to('x-small') {
            font-size: 14px !important;
            white-space: initial !important;
            font-weight: 600 !important;
            line-height: 20px !important;
          }
        
          @include respond-to('small') {
            font-size: 14px !important;
            white-space: initial !important;
            font-weight: 600 !important;
            line-height: 20px !important;
          }
        
          @include respond-to('medium') {
            font-size: 16px !important;
            white-space: nowrap !important;
            font-weight: 600 !important;
          }
        
          @include respond-to('large') {
            font-size: 16px !important;
            white-space: nowrap !important;
            font-weight: 600 !important;
          }
    }
}

.wm-term-link {
    color: #616161 !important;
    margin-right: 25px;
}

.wm-term-modal {
    @include respond-to('x-small') {
        max-height: 80vh;
        overflow-y: auto;
      }
    
      @include respond-to('small') {
        max-height: 80vh;
        overflow-y: auto;
      }
    
      @include respond-to('medium') {
        max-height: unset;
        overflow-y: unset;
      }
    
      @include respond-to('large') {
        max-height: unset;
        overflow-y: unset;
      }
}