@import "../variables";

$wm-text--color : $font-color;
$wm-border--color : $opt-border-color;


.wm{
    &-border-right{
        border-right: 1px solid $wm-border--color;
    }

      &-two-coll {

        color:$wm-text--color;

                // .wm_header{
                //     font-size: 25px;

                // }
                // .wm-sub_header{
                //     font-size: 15px;
                //     padding: 15px 0px;

                // }

                // .wm-search-cage{
                //         text-align: center;
                //         margin-top: 18px;
                //         margin: 0px 10px;

                //         .wm-booking-search{
                //             width: 80% !important;

                //             .mat-form-field-wrapper {
                //               margin: 0 !important;
                //               padding-bottom: 0 !important;

                //               .mat-form-field-flex {
                //                 padding: 0 5px !important;

                //                 .mat-form-field-infix {
                //                   padding: 0 !important;

                //                 }

                //               }

                //             }
                //         }
                // }


                .wm-new-booking-message {
                  padding: 25px 5px;
                  color: $provisional;
                }

                .wm-btn--cage-right{
                    text-align: center;
                    margin-top: 112px;
                    display: flex;
                    justify-content: center;

                }
                .wm-wast-type{
                  margin-left: 10px;
                }

                .wm-card-list {
                  margin: 10px 9px;

                  .wm-card-item {

                    @include respond-to('x-small') {
                      flex-direction: column;
                      padding: 12px 12px;

                    }
                    @include respond-to('small') {
                      flex-direction: column;
                      padding: 12px 12px;

                    }

                    @include respond-to('medium') {
                      flex-direction: column;
                      padding: 12px 12px;

                    }

                    @include respond-to('large') {
                      flex-direction: row;
                      @include box_shadow (1);
                      // box-shadow: 0px 0px 10px 0px rgb(223 223 223);
                      display: flex;
                      padding: 12px 12px;
                      background-color: white;
                      margin: 8px 0;
                      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                      border-radius: 5px;

                    }

                    &:hover {
                      box-shadow: 0px 0px 5px 3px #bdbdbd;
                    }



                    .wm-card-image {

                      @include respond-to('x-small') {
                        min-width: unset;
                        max-width: unset;
                        height: unset;

                      }
                      @include respond-to('small') {
                        width: 100%;
                        height: unset;

                      }

                      @include respond-to('medium') {
                        width: 100%;
                        height: 14vh;

                      }

                      @include respond-to('large') {
                        flex: 0 0 20%;
                        // min-width: 115px;
                        max-width: 115px;


                      }
                      
                      // &:hover {
                      //   border: 3px solid #00507c !important;
                      // }

                      .wm-img-one {

                        @include respond-to('x-small') {
                          width: 100%;
                          height: 180px;
                          object-fit: cover;
                        }
                        @include respond-to('small') {
                          width: 100%;
                          height: 180px;
                          object-fit: cover;
                        }

                        @include respond-to('medium') {
                          width: 100%;
                          height: 40vh;
                          object-fit: cover;
                          max-height: 85px;
                        }

                        @include respond-to('large') {
                          width: 100%;
                          height: 110px;
                          transition: all 0.5s ease;
                          object-fit: contain;
                          max-height: 85px;

                        }

                        &:hover {
                          transform: scale(1.2);
                        }

                      }
                    }

                    .wm-item-body-content-wrapper{

                      flex: 0 0 50%;
                      // padding: 0px 12px;

                      .wm-item-card-name{

                        @include respond-to('x-small') {
                          font-size: 16px;

                        }
                        @include respond-to('small') {
                          font-size: 16px;

                        }

                        @include respond-to('medium') {
                          font-size: 24px;

                        }

                        @include respond-to('large') {
                          font-size: 18px;
                          font-weight: 600;
                          color: black !important;
                        }
                      }
                      .wm-item-description{

                        @include respond-to('x-small') {
                          font-size: 11px;

                        }
                        @include respond-to('small') {
                          font-size: 11px;

                        }

                        @include respond-to('medium') {
                          font-size: 16px;

                        }

                        @include respond-to('large') {
                          font-weight: 600;
                          font-size: 11px;
                          color: #3fba74;
                        }


                        

                      }
                      .wm-item-dates-wrapper{

                        @include respond-to('x-small') {
                          display: flex;
                          margin-top: 15px;

                        }
                        @include respond-to('small') {
                          display: flex;
                          margin-top: 15px;

                        }

                        @include respond-to('medium') {
                          display: flex;
                          margin-top: 15px;

                        }

                        @include respond-to('large') {
                          display: flex;
                          margin-top: 20px;

                        }

                        .wm-item-date-m {
                          color: $collection-days-enable;
                          font-weight: 600;
                          margin-right: 5px;
                        }

                        .wm-item-date{
                          margin-right: 5px;
                          color: $collection-days-disable;

                        }
                      }
                    }

                    .wm-item-btn-wrapper {

                      @include respond-to('x-small') {
                        align-items: center;
                        padding: 8px 0px;

                      }
                      @include respond-to('small') {
                        align-items: center;
                        padding: 8px 0px;

                      }

                      @include respond-to('medium') {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0px 0px;
                      }

                      @include respond-to('large') {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        padding: 0px 0px;
                        flex: 0 0 30%;

                      }
                      .wm-btn-book {

                        .wm-btn__book {

                          @include respond-to('x-small') {
                            width: 145px;
    
                          }
                          @include respond-to('small') {
                            width: 145px;
    
                          }
    
                          @include respond-to('medium') {
                            width: 270px;
                          }
    
                          @include respond-to('large') {
                            width: 145px;
                            display: flex;
                            align-items: center;
    
                          }

                          

                          .mat-button-wrapper {
                            font-weight: 500 !important;
                          }
                        }

                        &:hover {
                          -webkit-box-shadow: 0px 1px 2px 0px #0000004a;
                        }

                      }

                      .wm-item-link {

                        @include respond-to('x-small') {
                          font-size: 13px;
  
                        }
                        @include respond-to('small') {
                          font-size: 13px;
  
                        }
  
                        @include respond-to('medium') {
                          font-size: 18px;
                        }
  
                        @include respond-to('large') {
                          padding: 5px 0px;
                          font-size: 13px;
  
                        }

                        // padding: 5px 0px;
                        // font-size: 13px;

                        .wm-collect-link {
                          color: #0275d8 !important;

                          &:hover {
                            text-decoration: underline;
                          }

                        }

                      }

                    }
                  }
                }
    }

}

.header-stepper {
  flex: 0 0 100% !important;
}

.wm-content-p-wrapper {
  @include respond-to('x-small') {
    padding: 0px 12px;

  }
  @include respond-to('small') {
    padding: 0px 12px;

  }

  @include respond-to('medium') {
    padding: 0px 30px;

  }

  @include respond-to('large') {
    padding: 0px 12px;
  }

}
