.wm-audit-history{

  .wm-modal-body{
    max-height: 56vh !important;
  }

  .wm-modal-footer{
    justify-content: flex-end !important;
    border-top: none !important;
  }

  .mat-paginator-page-size-label{
    width: 100% !important;
  }

  .wm-audit-history-spinner {
    text-align: center;
    margin-top: 120px;
 }
  .icon-blue {
    color: blue  !important;
  }
  .wm-view-doc {
    cursor:pointer;
  }
}


