.wm-main-body-wrapper{
    .cursor-not-allowed{
    cursor: not-allowed !important;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;

    &:active{
         pointer-events: none;

    }
  }
  .wm_custom_container{
    min-height: 450px !important;
  }
  .pt-40{
    padding-top: 40px;
  }
  .pb-40{
    padding-bottom: 40px !important;
  }
  .wm_container--inner{
    height: auto !important;
  }
  .wm-col-address-label{
    padding-top: 15px;
  }

}
