﻿@import url("https://fonts.googleapis.com/css?family=Muli:400,600,700&display=swap");
@import "../../themes/default/_variables-new.scss";
@import "_variables.scss";

.mat-dialog-container{
  .mat-dialog-content{
    .modal-header{
      button.mat-button:focus{
          border: unset !important;
      }
    }
  }
}



// --------------------------- Legacy CSS ------------------------


* {
  font-family: "Muli", sans-serif !important;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: unset;
  color: unset;
}

.material-icons {
  font-family: "Material Icons" !important;
}

.payment{
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.body-search {
  background-color: #fff;

  .body-search-container {
    background-color: #f5f5f5;

    .mat-button-toggle-appearance-standard {
      background: #d4007f;

      &.mat-button-toggle-checked {
        background-color: #c9da43 !important;

        .material-icons {
          color: #000 !important;
        }
      }

      .mat-button-toggle-label-content {
        line-height: 45px;
      }

      .material-icons {
        font-size: 22px;
        color: #ffffff;
      }
    }

    .mat-button-toggle-appearance-standard:hover {
      background: #000000;

      &.mat-button-toggle-checked {
        background-color: #000 !important;

        .material-icons {
          color: #c9da43 !important;
        }
      }

      .material-icons {
        color: #c9da43;
      }
    }

    .confirmation {
      .pw-text-color__tertiary {
        color: #000 !important;
        font-size: 16px;
        letter-spacing: unset;
        word-spacing: unset;
      }

      i {
        display: none;
      }
    }

    .no-venu {

      .pw-text-color__quinary {
        color: #000 !important;
      }
    }

  }

  .three-tab-cal-main-wrapper{
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
      color: white;
    }
    .mat-button-toggle-appearance-standard:hover{
      span{
        color: #c9da43;
      }
    }
    .mat-button-toggle-appearance-standard.mat-button-toggle-checked{
      span{
        color: #000 !important;
      }
    }
    .mat-button-toggle-appearance-standard:hover.mat-button-toggle-checked span {
      color: #c9da43 !important;
    }
  }
}
.btn-got-cart .btn-cal-view-apply{
  border: 1px solid #d4007f !important;
  color: #d4007f !important;
}
.btn-got-cart .btn-cal-view-apply:hover{
    background-color: #d4007f !important;
    color: #ffffff !important;
}
.price-icon-wrapper .info-icon mat-icon {
  color: #d4007f !important;
}
.showInWeb .cal-date{
  background: #fff5fb !important;
  color: #d4007f !important;
}

.txt-color-same {
  color: $pw-text-color__primary !important;
}
.body-wrap {
  .block-user-alert-user-alert{
    border: none !important;
  }
}

// customer registration 

.mmc_corporate {
  .remove_right_padding.d-none {
    display: block !important;
  }

  .user-name {
    flex: 0 0 75% !important;

    @media screen and (max-width: 375px) {
      flex: 0 0 100% !important;
    }
  }
}

//booking search

.past-booking .booking-list .mat-expansion-panel-body {
  background-color: #f1f8ff !important;
  border-left: 4px solid #044fa2 !important;
}

.header__btn--search {
  @media only screen and (max-width: 1200px) {
    display: none !important;
  }
}

.delete-modal {
  margin: 24px !important;
}

//edit modal

.mat-calendar-table-header {
  th {
    border-bottom: 0 !important;
  }
}

.mat-calendar-table-header-divider {
  padding: 0 !important;
  border-bottom: 0 !important;
}

.mat-calendar-body {
  border-top: 0 !important;
}

/*.edit-booking-modal{
    .mmc-modal-body{
        .mat-datepicker-content{
               .mat-calendar-table-header-divider{
            padding:0 !important;
             border-bottom:0 !important;
}
        }

    }
    }*/

.cancel-booking-modal .mmc-modal-body {
  @media screen and (max-width: 414px) {
    max-height: 80vh;
  }
}

.edit-booking-modal {
  .mmc-modal-footer {
    .btn__card {
      /*background-color:#D4007F !important;
            border: 1px solid #D4007F !important;*/
    }
  }

  .material-icons {
    color: #616161 !important;
  }
}

.edit-modal {
  .edit-timeSlot {
    @include respond-to("x-small") {
      flex-direction: column;
    }

    @include respond-to("small") {
      flex-direction: column;
    }

    @include respond-to("medium") {
      flex-direction: column;
    }

    @include respond-to("large") {
      flex-direction: row;
    }

    @include respond-to("x-large") {
      flex-direction: row;
    }
  }

  .category-event-selection-row {
    flex-direction: column !important;
  }

  .edit-row {
    .item-availability {
      .title {
        color: #007A4B !important;
      }

      .available-chip {
        border: 1px solid #007A4B !important;
        color: #007A4B !important;
      }
    }
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: #000 !important;
    background-color: #fff;

    .mat-form-field-outline-start {
      border-radius: 2px 0 0 2px;
    }

    .mat-form-field-outline-end {
      border-radius: 2px 0 0 2px;
    }
  }
}

.agm-map-container-inner {
  width: auto !important;
}

.fa {
  font-family: "FontAwesome" !important;
}

.slider-detail--wrapper {
  background: black;
  transition: 0.3s;

  &:hover {
    background-color: #000000e3;
  }

  .mat-form-field {
    .mat-icon {
      font-size: 24px;
      color: #ffeb3b;
    }

    .mat-form-field-label {
      color: #fff !important;
    }
  }

  .btn_book {
    .btn__card {
      /*background-color: #D4007F !important;
            border: 1px solid #D4007F !important;*/
    }
  }

  .pw-text-color__secondary {
    color: #ffffff;
  }
}

.nav {
  @include respond-to("x-small") {
    top: calc(8.25rem - 1px);
    position: absolute;
    left: 0;
    width: 100%;
    background: #000;
    display: block;
    height: calc(100vh - 6rem);
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
  }

  @include respond-to("small") {
    top: calc(8.25rem - 1px);
    position: absolute;
    left: 0;
    width: 100%;
    background: #000;
    display: block;
    height: calc(100vh - 6rem);
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
  }

  @include respond-to("medium") {
    position: absolute;
    top: calc(8.25rem - 1px);
    left: 0;
    width: 100%;
    background: #000;
    display: block;
    height: calc(100vh - 6rem);
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
  }

  @include respond-to("large") {
    position: absolute;
    top: unset;
    left: auto;
    right: auto;
    width: 100vw;
    background: unset;
    display: flex !important;
    justify-content: center;
    height: auto;
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
    margin: 0px !important;
  }

  @include respond-to("x-large") {
    position: absolute;
    top: unset;
    left: auto;
    right: auto;
    width: 100vw;
    background: unset;
    display: flex !important;
    justify-content: center;
    height: auto;
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
    margin: 0px !important;
  }
}

.nbc-hide-mobile {
  display: none !important;

  @media screen and (min-width: 1200px) {
    display: flex !important;
  }

  /*@include respond-to("x-small"){
      display:none !important;
   }
    @include respond-to("small"){
      display:none !important;
   }
   @include respond-to("medium"){
      display:block !important;
   }
   @include respond-to("large"){
      display:block !important;
   }
   @include respond-to("x-large"){
      display:block !important;
   }*/
}

.nbc-show {
  display: block !important;
}

.region-bottom .footer {
  display: block !important;
}

.mmc_cart {
  .cart_facility--icon {
    .card-icon {
      .count {
        background-color: #044fa2;
      }
    }
  }
}

.cart_facility {
  z-index: 998 !important;
}

.header {
  height: auto !important;
  padding: 0px !important;
  z-index: 999 !important;

  &__links-btn>a {
    background-color: #c9da43 !important;
    color: #000;
    padding: 0 1em;
    display: flex;
    height: 100%;
    align-items: center;
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }
}

.header__wrap {
  height: 5.5rem;

  h1 {
    font-size: unset;
    margin-bottom: 0px !important;
  }

  .nav {
    margin-right: 170px;
  }
}

.header__actions {
  @include respond-to("x-small") {
    display: flex;
  }

  @include respond-to("small") {
    display: flex;
  }

  @include respond-to("medium") {
    display: flex;
  }

  @include respond-to("large") {
    display: none;
  }

  @include respond-to("x-large") {
    display: none;
  }
}

.nav .contextual-links-region {
  position: static;
}

.nav .region-navigation {
  height: 100%;
}

@media screen and (max-width: 74.9375em) {
  [data-menu="true"][data-search="true"] .nav {
    display: none !important;
  }

  [data-menu="true"] .nav {
    position: absolute;
    top: calc(8.25rem - 1px);
    left: 0;
    width: 100%;
    background: #000;
    display: block !important;
    height: calc(100vh - 6rem);
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
  }
}

.navbar-collapse {
  @include respond-to("x-small") {
    margin: 0;
  }

  @include respond-to("small") {
    margin: 0;
  }

  @include respond-to("medium") {
    margin: 0 20px !important;
  }

  @include respond-to("large") {
    margin: 0 20px !important;
  }

  @include respond-to("x-large") {
    margin: 0 20px !important;
  }
}

.footer a {
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

opt-secure-pay{
  display: flex;
  justify-content: center;  
}

.snow.nbc .region-bottom .footer {
  background-color: #000 !important;

  &__phone {
    font-size: 2.5em !important;
    margin-bottom: 0.25em !important;
  }

  &__logo {
    display: flex !important;
    justify-content: center !important;

    img {
      height: unset;
    }
  }
  .footer-contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a{
      margin-bottom: 30px !important;
    }
  }
}

.main_search {
  box-shadow: unset;

  .material-icons {
    color: #616161 !important;
  }

  .placeholder {
    &.pw-txt-b2 {
      font-size: 18px;
      color: #2b2b2b;
    }
  }

  .btn--cage {
    .btn--search {
      background-color: #000;
      border-right: 1px solid #ccc;

      &:hover {
        background: #c9da43 !important;
        color: #000 !important;

        i {
          color: #000 !important;
        }
      }
    }
  }
}

.search--result {
  .content {
    h1 {
      a {
        text-decoration: none;
        color: #000;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .pw-text-color__tertiary {
    color: #000;
  }
}

.agm-info-window-content {
  .search--result {
    box-shadow: unset;
    border: unset;

    .item_name {
      letter-spacing: unset;
      word-spacing: unset;
    }

    .item_address {
      color: #000 !important;

      i {
        color: #000 !important;
      }
    }

    .item_details {
      word-spacing: unset;
      letter-spacing: unset;
    }
  }

  .available-cage {
    position: absolute !important;
    top: -20px;
  }
}

.search--result {
  box-shadow: unset;
  border: unset;

  .item_name {
    letter-spacing: unset;
    word-spacing: unset;
  }

  .item_address {
    color: #000 !important;

    i {
      color: #000 !important;
    }

    .mmc_flex--container {
      .pw-txt-lh-1 {
        @media screen and (max-width:768px) {
          line-height: 1.2 !important;
        }
      }
    }
  }

  .item_details {
    word-spacing: unset;
    letter-spacing: unset;
  }

  .butn__cage {
    @media screen and (max-width:768px) {
      margin-top: 40px !important;
    }

    .price {
      @media screen and (max-width:768px) {
        padding-top: 30px !important;
      }
    }
  }

}

.facility-search-input {
  .mmc_flex__item--inherit {
    display: flex !important;
  }

  .mat-form-field-label {
    color: #000 !important;
  }
}

.filter {
  color: #000 !important;

  .mat-button {
    background-color: #c9da43;

    span {
      color: #000 !important;
    }
  }
}

.count-cage {
  color: #044fa2;

  .confirmation {
    i {
      color: #044fa2;
    }
  }
}

.font__nt-available {
  color: #e12890;
}

.search--result {
  .available-cage {
    .icn-aminity {
      color: #d32f2f;
    }

    .pax {
      color: #d32f2f;
      line-height: 24px;
    }

    .link {
      color: #d32f2f;
      font-weight: 700;
    }

    &.partially-available {
      .icn-aminity {
        color: #044fa2;
      }

      .pax {
        color: #044fa2;
        line-height: 24px;
      }

      .link {
        color: #044fa2;
        font-weight: 700;
      }
    }

    &.fully-available {
      .icn-aminity {
        color: #007a4b;
      }

      .pax {
        color: #007a4b;
        line-height: 24px;
      }

      .link {
        color: #007a4b;
        font-weight: 700;
      }
    }
  }

  .image-cage:hover {
    border: 3px solid #044fa2;
  }

  .content {
    >div {
      a:hover {
        color: $pw-text-color__quaternary;
      }
    }
  }

  .pw-txt-h3_small--link {
    font-weight: 700;
    letter-spacing: 0;
    word-spacing: 0;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
      color: inherit !important;
    }
  }

  .description-text {
    letter-spacing: unset !important;
    word-spacing: unset !important;

    ul {
      padding-left: 15px;
    }

    li {
      list-style-type: disc !important;
      margin-left: 20px;
    }
  }

  .item_address {
    color: #000 !important;

    i {
      color: #000000 !important;
    }
  }
}

.search--result {
  .btn__card {
    &.main {
      color: $white;
      background-color: $select-color;
      border: 1px solid $select-color;
    }

    &.secondary {
      background-color: #044fa2 !important;
      border: 1px solid #044fa2 !important;
    }
  }
}

.setup_page {
  .btn__card {
    &.main {
      background-color: #044fa2 !important;
      border: 1px solid #044fa2 !important;

      &:hover {
        color: $white !important;
      }
    }
  }
}

.edit-booking-modal {
  .mmc-modal-footer {
    .btn__card {
      background-color: $select-color !important;
      border: 1px solid $select-color;

      &:hover {
        background-color: $select-color !important;
      }
    }
  }
}

.user {
  color: #000 !important;

  .sub {
    .link-primary {
      color: #0054a5 !important;
      cursor: unset;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.user__login {
  color: #044fa2 !important;
}

.user__avatar {
  cursor: pointer;
}

.user__cart {
  background-color: #c9da43 !important;

  .user__counter {
    background-color: #044fa2;
  }

  i {
    color: #000 !important;
  }
}

.no-venu {
  .confirmation {
    margin-top: 10px;
    padding: 15px;
    background-color: #99bbdb3b;
    border-left: 5px solid #0054a5;
  }
}

.mmc-btn-primary,
mmc-btn-primary--small {
  color: #044fa2 !important;
  border: 1px solid #044fa2 !important;

  &:hover {
    background-color: #044fa2;
    color: #fff;
  }
}

.corporate-customer-wrapper,
.individual-customer-wrapper {
  .add-more-btn {
    &:hover {
      background-color: #044fa2;
    }
  }

  .account-customer-name {
    border-left: 15px solid #c9da43;
  }
}

.title-secondary {
  color: #000;
}

ul.breadcrumb a {
  color: #044fa2;
}

.mat-form-field-flex:hover {
  .mat-form-field-outline-thick {
    color: #044fa2 !important;
  }
}

.enquiry-submit {
  .cart {
    background-color: #c9da43 !important;

    i {
      color: #000 !important;
    }
  }

  .bottom-section {
    .btn__card.submit {
      background-color: #044fa2 !important;
      border: 1px solid #044fa2;
    }
  }
}

.mmc_cart {
  .snipper-body {
    .left-side {
      .mmc_card {
        .btn__validate {
          @include respond-to("x-small") {
            min-width: 185px !important;
          }

          @include respond-to("small") {
            min-width: 240px !important;
          }

          @include respond-to("medium") {
            min-width: 290px !important;
          }

          @include respond-to("large") {
            min-width: 290px !important;
          }

          @include respond-to("x-large") {
            color: #fff !important;
            background-color: #000 !important;
            min-width: 290px !important;
            max-height: 70px !important;
          }

          .count {
            color: black;
            border: 2px solid white !important;
            background-color: white !important;
          }

          .material-icons {
            color: white !important;
          }

          &:hover {
            background-color: #c9da43 !important;
            color: #000 !important;

            .count{
              border: 2px solid black !important;
              color: white;
              background-color: black !important;
            }

            .material-icons {
              color: black !important; 
            }
          }


        }
      }
    }
  }
}

[class^="icon-"] {
  background: none;
}

.document-upload {
  .title {
    span {
      color: #e12890;
    }
  }
}

mat-step-header {
  position: relative !important;
  z-index: 998;
}

.ngx-gallery-arrow-wrapper,
.ngx-gallery-icon {
  z-index: 998 !important;
}

.mat-dialog-container {
  padding: 0px;
}

.doc-icon {
  background-color: #044fa2 !important;
}

.down-icon {
  color: #000 !important;
}

.booking--details {
  .title {
    .name {
      span {
        color: #044fa2;
      }
    }
  }

  .details-section {

    .pw-txt-b1,
    .pw-txt-b2 {
      color: #000;
    }

    li {
      span {
        color: #000;
      }
    }
  }
}

.custom-dialog-container {
  .mat-dialog-content {
    overflow: hidden;
    max-height: 80vh;
    margin: 0px;
    padding: 0px;

    .mat-dialog-content {
      .modal-header {
        background: unset;
      }

      .modal-body {
        overflow: hidden;
        max-height: 70vh;
      }
    }
  }

  .modal-body {
    /*overflow-y: scroll;*/
    max-height: 70vh;
  }

  .modal-header {

    /*padding:15px;*/
    /*background-image: linear-gradient(#007cb8, #044fa2);*/
    >.mmc_flex--container {
      align-items: center;
    }
  }
}

.calender-modal-wrapper {
  .modal-header {
    max-height: 70vh;
    background-color: #fff !important;
  }

     .modal-body {
        //overflow-y:auto !important;
        .calendar-modal{
            >.container{
                min-width:unset !important;
                width:95%
            }
        }
    }
}

.filter--menu {
  .mat-form-field-label {
    color: #616161 !important;
  }

  input {
    color: #000;
  }

  .mat-select-value-text {
    span {
      color: #000;
    }
  }
}

.filter-sec {
  .mat-form-field {
    height: 60px;
  }

  .icon--small {
    vertical-align: unset;
    line-height: 15px !important;
  }
}

.form-field-error {
  &.mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: #d32f2f !important;
    }
  }
}

.booking-list {
  .mmc-btn-secondary--small {
    background-color: #044fa2 !important;
    border: 1px solid #044fa2 !important;
    color: white !important;
    margin: 0px 5px !important;

    @media screen and (max-width:768px) {
      margin: 0px 0px !important;
    }

    &:hover {
      background-color: #fff !important;
      color: #044fa2 !important;
    }
  }
  button.mmc-btn-secondary--small {
    background-color: #ffffff !important;
    border: 1px solid #044fa2 !important;
    color: #044fa2 !important;
    margin: 0px 5px !important;

    @media screen and (max-width:768px) {
      margin: 0px 0px !important;
    }

    &:hover {
      background-color: #fff !important;
      color: #044fa2 !important;
    }
  }
  .mat-button[disabled]{
    border: none !important;
    color: #9e9e9e !important;
    box-shadow: none !important;
    background-color: #e9e9e9 !important;
  }

  .my-bookings-expansion-body {
    h6 {
      font-size: 16px;
    }
  }
}

.btn__card.main.disable-div-btn{
  border: none !important;
  color: #9e9e9e !important;
  box-shadow: none !important;
  background-color: #e9e9e9 !important;;
}

a {
  text-decoration: none;
}

[class^="icon-"] {
  padding: unset;
  width: unset !important;
  height: unset !important;
  display: unset;
}

.past-booking-details {
  .past-booking-details--card-fourth {
    .status {
      background-image: none !important;
    }
  }

  .p80 {
    width: 80%;
  }

  .pl-30 {
    padding-left: 30px;
  }

  .pl-60 {
    padding-left: 60px;
  }

  .uploaded-file-list--card {
    .icon-col {
      background-color: #044fa2;
    }

    .download-col {
      .material-icons {
        &:hover {
          color: #044fa2;
        }
      }
    }
  }
}

.past-booking-details--card {
  .total-field {
    line-height: 25px;
  }
}

.expansion-header-icons {
  .icon-p2-questions {
    padding-right: 0px !important;
  }
}

.pw-text-color__quinary {
  color: #044fa2;
}

.pw-ico-color__quinary {
  color: #000;
}

.mat-calendar-body-selected {
  background-color: #044fa2 !important;
}

.avl-lbl {
  .pw-text-color__quinary {
    color: #fff;
  }
}

.btn_book {
  .btn__card {
    background-color: $select-color !important;
    border: 1px solid $select-color !important;
  }
}

.pw-text-color__quaternary {
  color: #044fa2;
}

.mat-datepicker-toggle-active {
  color: #044fa2 !important;
}

.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: #044fa2 !important;
  }

  .mat-radio-inner-circle {
    background-color: #044fa2 !important;
  }
}
timepicker {
  /*.btn{
        background: #044fa2 !important;
    }*/

  button {
    &.btn {
      background-color: #044fa2 !important;
    }
  }
}

opt-calendar-modal {
  .mat-dialog-content {
    margin: 0px !important;
    padding: 0;
  }
}

.mat-tab-label-active {
  background: #044fa2 !important;
}

.mat-ink-bar {
  background: #044fa2 !important;
}

.expansion-header-icons {
  .count {
    background: #044fa2 !important;
    color: white;
  }
}

.mmc-btn-xl--fourth {
  color: #044fa2 !important;

  &:hover {
    background: #044fa2 !important;
    color: #fff;
  }
}

.mmc-modal-header {
  .icon-cage {
    background: #044fa2 !important;
  }
}

.add-extras-modal {
  .btn--search {
    background: #044fa2 !important;
    color: #fff;

    &:hover {
      background: #fff !important;
      color: #044fa2;
    }
  }
}

.mat-spinner {
  circle {
    stroke: #044fa2 !important;
  }
}

.nbc-payment-wrapper {
  position: relative;

  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.past-booking-details {
  .past-booking-details--card-fourth {
    .status {
      background-image: none !important;
    }
  }

  .p80 {
    width: 80%;
  }

  .pl-30 {
    padding-left: 30px;
  }

  .pl-60 {
    padding-left: 60px;
  }
}

.booking-cart {
  .right-side {
    .bottom-section {
      .pw-text-regular {
        font-weight: 600;

        &:hover {
          color: #044fa2;
          text-decoration: underline;
        }
      }
    }
  }
}

.past-booking-details-container {
  .secondary-header-row {
    .btn__card {
      background-color: #044fa2 !important;
      border: #044fa2 !important;
    }
  }

  .inner-container {
    .mmc_flex__item--hcenter {
      .btn__card {
        background-color: #044fa2 !important;
        border: #044fa2 !important;
      }
    }
  }

  .past-booking-details--card-ternary {
    .payment-summery--card {
      .mmc_flex__item {
        justify-content: center;
        display: flex;

        &.mmc_flex__item--hcenter {
          flex: 0 0 10%;
        }

        &.msg-status {
          flex: 0 0 15%;
        }

        &.msg-content {
          flex: 0 0 75%;
        }
      }
    }
  }
}

.opt-regular-booking {
  .mat-horizontal-content-container {
    overflow: hidden !important;
  }

  .wp-rollover-booking-modal {
    .term-card {
      overflow: hidden;
      padding: 15px;
    }
  }

  .ngucarousel {
    .ngucarousel-items {
      flex-wrap: nowrap !important;
    }
  }
}

.cart-panel {
  padding-top: 12px;
  padding-bottom: 30px;

  .mat-expansion-panel-header-title {
    font-weight: 700;
  }

  table {
    thead {
      th {
        // text-align: center !important;
        font-weight: 600 !important;
        // color: black !important;
        font-size: 0.875rem !important;
        padding-left: 50px !important;

      }
    }

    tbody {
      td {
        // text-align: center !important;
        font-size: 0.875rem !important;
        font-weight: 600 !important;
        color: #616161 !important;
        padding-left: 50px !important;
      }
    }

  }
}

.panel-total-wrapper {
  display: flex;
  justify-content: space-between;
  // flex-direction: column;

  .panel-totle-text {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .panel-totle-price {
    font-size: 0.875rem;
    font-weight: 600;
  }
}

.panel-event-total-wrapper {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;

  .panel-event-totle-text {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .panel-event-totle-price {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 6px;
  }
}

.cart-panel {
  .mat-expansion-panel {
    @include box_shadow (1);

    .mat-expansion-panel-body {
      padding: 0 !important;

      .cart-panel-table {
        box-shadow: none !important;
        margin-bottom: 0 !important;
        background-color: #eee !important;
      }
    }
  }
}


.cart-panel {
  .mat-expansion-panel-header {
    background-color: #eee !important;

  }

  .mat-expanded {
    background-color: white !important;
  }
}


.show-additional-charge-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  background-color: white;

  .additional-charge-label {
    flex: 0 0 50%;
    text-align: left;
    // font-weight: 700;
    padding-left: 50px;
  }

  .additional-charge-total {
    flex: 0 0 50%;
    text-align: right !important;
    padding-right: 50px;
    // font-weight: 700;
  }
}

table {
  thead {
    background-color: white !important;

    tr {
      .mat-column-unit_price {
        text-align: center !important;
      }

      .mat-column-quantity {
        text-align: center !important;
      }

      .mat-column-total {
        text-align: right !important;
        padding-right: 50px !important;

      }
    }
  }
}

table {
  tbody {
    tr {
      .mat-column-unit_price {
        text-align: center !important;
      }

      .mat-column-quantity {
        text-align: center !important;
      }

      .mat-column-total {
        text-align: right !important;
        padding-right: 50px !important;

      }
    }
  }
}

.cart-panel {
  .cart-header-wrapper {
    display: flex;
    width: 100%;

    .cart-panel-header-title {
      flex: 0 0 50%;
      display: flex;
      align-items: center;

    }

    .cart-header-attendees-wrapper {
      flex: 0 0 50%;
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .cart-header-attendees-label {}

      .cart-header-attendees-textbox {
        display: flex;
        justify-content: flex-end;

        .mat-form-field {
          width: 100% !important;

          .mat-form-field-wrapper {
            padding-bottom: 0 !important;

            .mat-form-field-flex {
              height: 40px !important;

              .mat-form-field-outline {
                background-color: #eee !important;
              }

              .mat-form-field-infix {
                padding: 0 !important;
              }
            }

          }
        }

      }
    }
  }
}

.search-btn-component {
  width: unset !important;
  flex: 1 0 !important;
}

.detail-before::before {
  content: ' Facilities of the same Venue';
  position: absolute;
  width: 340px;
  height: 100%;
  background-color: #eee;
}

.mmc_cart {
  .pw-text-color__quinary {
    @include respond-to("x-small") {
      color: black !important;
    }

    @include respond-to("small") {
      color: black !important;
    }

    @include respond-to("medium") {
      color: black !important;
    }

    @include respond-to("large") {
      color: black !important;
    }

    @include respond-to("x-large") {
      color: black !important;

    }
  }
}

.edit-booking-modal {
  .edit-modal-header {
    height: 90px;

    .sub-title-cage {
      line-height: 2rem;
    }

    .facility-cage {
      white-space: nowrap !important;
      margin-left: 0px !important;
    }

  }

  .close-icon-cage {
    button {
      height: 100% !important;
    }

  }
}

.cancel-booking-modal {
  .inner {
    .pw-txt-b2 {
      font-size: 0 !important;

      &::after {
        // content: 'Are you sure you want to request cancellation of this booking? \a \a Please note that requesting a cancellation will remove the booking and the reservation at this venue. If you only wish to make a change or modify the booking please contact Council. \a \a For Open Space bookings phone 8495 5009 or email openspacebookings@northernbeaches.nsw.gov.au \a For Community Centre bookings phone 8495 5012 or email communitycentres@northernbeaches.nsw.gov.au';
        content: 'Are you sure you want to request cancellation of this booking? \a \a By requesting a cancellation you will lose your reservation at this venue. Please ensure you are aware of the cancellation terms and conditions prior to doing so.\a If you would only like to make a change or modify the booking please contact Council. \a \a For Open Space bookings phone 8495 5009 or email openspacebookings@northernbeaches.nsw.gov.au \a For Community Centre bookings phone 8495 5012 or email communitycentres@northernbeaches.nsw.gov.au';
        white-space: pre-line;
        font-size: 0.875rem;
        margin-left: -25px;

        @media screen and (max-width: 414px) {
          width: 235px;
          height: 360px;
          overflow-wrap: break-word;
        }
      }
    }
  }
}

.snipper .activity-title {
  @media screen and (max-width: 768px) {
    font-family: inherit !important;
  }


}

.snipper .mat-horizontal-stepper-header .mat-step-icon .mat-step-icon-content {
  display: none;
}

.snipper .mat-horizontal-stepper-header .mat-step-icon {
  margin-top: 3px;
}

.past-booking-details--card-secondary {
  padding: 0px !important;
}

.facility-expansion-header--container {
  .mmc_flex__item {
    .mcc-wrap-items {
      @media screen and (max-width: 577px) {
        display: flex;
        flex-direction: column;
      }

      .mmc_flex__item {
        @media screen and (max-width: 577px) {
          display: flex;
          flex-direction: row;
          width: 100% !important;
          justify-content: space-between;
        }
      }
    }
  }
}

.agm-info-window-content {
  .mmc_flex__item content {
    .item_details {
      .pw-text-color__tertiary {
        text-align: left;
      }
    }
  }
}

.no-left-padding {
  padding-left: 15px !important;
}

.no-right-padding {
  padding-right: 15px !important;
}

.wm-modals-container .mmc-modal-header {
  z-index: 111;
  height: 70px;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.12), 0 1px 2px 0px rgba(0, 0, 0, 0.24);
  position: relative;
}

.wm-custom-modal-wrapper .wm-header .sub-header-left ._icon-carge {
  padding: 10px 25px;
  background-color: $header-icon-bg-color;
  margin-right: 25px;
}

.wm-custom-modal-wrapper .wm-header .sub-header-left ._icon-carge .wm-client-search-icon {
  position: relative;
  top: 8px;
  font-size: 45px !important;
  color: black;
}

.wm-custom-modal-wrapper .wm-client-search-body {
  padding: 15px;
}

.wm-modals-container .mmc-modal-header .close-icon-cage {
  width: 70px;
  color: #3a424d;
}

.wm-custom-modal-wrapper .wm-header .sub-header-left,
.wm-custom-modal-wrapper .wm-header .sub-header-right {
  display: flex;
  align-items: center;
}

.wm-client-search-popup .mat-dialog-container {
  overflow-y: hidden !important;
  margin-top: 0px;
  height: 85%;
}

.wm-custom-modal-wrapper .wm-header .sub-header-left .left-header-title span,
.wm-custom-modal-wrapper .wm-header .sub-header-right .left-header-title span {
  font-size: 20px;
  font-weight: 600;
}

.wm-custom-modal-wrapper .wm-header {
  justify-content: space-between;
}

.wm-client-search-popup {
  margin-top: 30px;
  max-width: 80vw;
  pointer-events: auto;
  width: 100vw;
  height: auto !important;
  position: static;
  max-width: 90vw !important;
}
.wm-modals-container .mmc-modal-header .close-icon-cage button {
  margin-left: -20px;
}

.wm_search-add-client_iframe {
  width: 100%;
  height: calc(100vh - 230px) !important;
  border: none;
}

span.wp-txt-body1--small.wp-txt--link.header_nav.wm-selected-customer-profile.wm-select-profile span:before {
  display: none !important;
}

.wm-login-user-profile-icon-wrapper.wm-selected-customer-profile {
  display: flex;
  align-items: center;
}

span.wp-txt-body1--small.wp-txt--link.header_nav.wm-selected-customer-profile.wm-select-profile {
  display: flex;
}

.opt_modal-content .mat-dialog-content .opt_modal-header {
  max-width: unset !important;
}

.mat-calendar-table {
  border: 1px solid white !important;

  .mat-calendar-table-header {
    tr {
      th {
        border: 1px solid white !important;
      }
    }
  }

  .mat-calendar-body {
    tr {
      td {
        border: 1px solid white !important;
      }
    }
  }

}

// responsive changes //

.mmc_card {
  &.search--result {
    .content {
      @media only screen and (max-width : 375px) {
        .aminity-icons--cage {
          height: 80px;

          .aminity-icons {
            &:before {
              top: 0% !important;
            }

            .icon-list img {
              height: 18px !important;
              width: 18px !important;
            }
          }

          .mmc_flex__item--inherit {
            position: absolute;
            top: 50px;
          }
        }
      }
    }

    .butn__cage {
      @media only screen and (max-device-width : 375px) {
        .price {
          margin-top: 35px;
          padding: 0;
        }
      }
    }
  }
}

.search-map {
  .agm-map-container-inner {
    .gm-style-iw-c {
      @include respond-to("x-small") {
        max-width: 400px !important;
      }

      @include respond-to("small") {
        max-width: 400px !important;
      }

      @include respond-to("medium") {
        max-width: 700px !important;
      }

      @include respond-to("large") {
        max-width: 1100px !important;
      }

      @include respond-to("x-large") {
        max-width: 1100px !important;
      }

      .agm-info-window-content {
        .aminity-icons--cage {
          .available-cage {
            position: unset !important;
          }
        }
      }
    }
  }
}

.time-popup {
  margin-top: 10px;
}

.table-no-border {
  timepicker {
    table {
      border-collapse: unset !important;
      border: none !important;
      margin-bottom: 0rem;

      tbody {
        border-top: none !important;

        tr {
          td {
            border: 1px solid white !important;
          }

        }
      }

    }
  }

  timepicker tbody {
    border-top: none !important;
    border: none !important;
  }

}

.questionnaire-modal {
  .mmc-modal-body {
    .mcc-expansion-panel-inner {
      .questionnaire-type {
        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            .questionnaire-accordion {
              .mat-accordion {
                .mat-expansion-panel {
                  .mat-expansion-panel-header {
                    .mat-content {
                      .mat-expansion-panel-header-title {
                        flex-grow: unset !important;
                      }

                      span {
                        color: red !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.mat-radio-label {
  align-items: flex-start;
  white-space: normal;
}

.after-date-warning {
  background-color: #FFD300;

  .col{
    padding: 0 !important;
  }
  .font__h5{
    margin: 0 !important;
  }

  .inner-wrapper {
    .material-icons {
      color: rgb(255, 255, 255) !important;
    }

    .middle-content {
      .text-content {
        .wider-text {
          // letter-spacing: 0 !important;
          // line-height: 1.4 !important;
          color: rgb(255, 255, 255);
          max-width: 100% !important;
        }
      }

    }

    .close-icon-cage {
      button {
        .mat-button-wrapper {
          .material-icons {
            color: black !important;
          }
        }
      }
    }
  }


}

.edit-booking-modal {
  .mmc-modal-body {
    .edit-modal {
      .available-msg {
        font-size: 12px !important;
      }
    }
  }

  tbody {
    border-top: none !important;
  }
}

tbody {
  border-top: none !important;
}

.search--result {
  .item_details {
    // @media screen and (max-width: 767px) {
    //     height: 235px !important;
    // }

    // .facility-short-description {
    //     overflow: unset !important;
    // }
  }

}

.hide-from-header {
  display: none;
}

.price-justify {
  text-align: justify;
}


.mat-input-element {
  .date {
    .date-small-font {
      @media screen and (min-width: 1024px) {
        font-size: 15px;
      }
    }

  }
}

.booking-detail-check {
  flex: 0 0 90%;
  display: flex;
  align-items: center;
  padding-bottom: 0 !important;

  .mat-checkbox-layout {
    .mat-checkbox-inner-container {
      margin: unset !important;
      margin-right: 8px !important;
      top: 3px !important;
    }
  }
}

.facility-short-description {
  display: flex;
  flex-direction: column;
}

.booking-detail-btn-wrapper {
  flex: 0 0 10%;

  .booking-details-button {
    padding: 0 15px !important;
  }
}

.cancelBooking-btn {
  margin-right: 5px !important;
}

.icon-list {
  @media screen and (max-width:768px) {
    width: 50% !important;
    top: 0px;
  }

  @media screen and (max-width:375px) {
    width: 100% !important;
  }
}


.booking-details-review-section {
  display: none;
}

.price-justify {
  text-align: justify;
}


.mat-input-element {
  .date {
    .date-small-font {
      @media screen and (min-width: 1024px) {
        font-size: 15px;
      }
    }

  }

}

.mat-radio-label {
  white-space: normal !important;
}

.input-error {
  border: red 1px solid;
  color: red;

}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 5px;
}

.expansion-content {
  display: flex;
  justify-content: center;
}

.loader2 {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  left: 50%;
  position: relative;
  top: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//nav bar
.navbar-light {
  .sub_left {

    // @media screen and (max-width: 1024px) {
    //   padding-bottom: 20px !important;
    //   width: 100% !important;
    //   border-bottom: 1px solid #b7b7b7;
    // }

    @media screen and (max-width: 768px) {
      flex: 0 0 100%;
      max-width: unset;
      width: 100% !important;
      justify-content: space-between;
      padding-bottom: unset !important;

      .wp-mr-20:nth-child(1) {
        width: 20% !important;
        margin-right: 5px !important;
      }

      .wp-mr-20:nth-child(2) {
        width: 35% !important;
        margin-right: 5px !important;
      }
    }

    @media screen and (max-width: 375px) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 10px;

      .wp-mr-20 {
        padding-bottom: 10px;
      }
    }
  }
}

.edit-modal-booking-header {
  .calender-modal-wrapper {
    margin-top: 15px !important;
  }
}

.mmc_cart .snipper-body .left-side .mmc_card .btn__validate.add-venue .txt-add-more::after {
    content: "/DATE(S)";
    position: relative;
    top: -23px;
    left: 90px;
    font-weight: 600;

    @media screen and (max-width:1024px) {
        content: '/\00a0 DATE(S)';
        top: -22px;
    }
    @media screen and (max-width:768px) {
        content: '/\00a0 DATE(S)';
        top: -21px;
    }
}

.mmc_cart .snipper-body .left-side .mmc_card .btn__validate.add-venue div:nth-child(2) {
  display: flex;
  white-space: nowrap;
}

.mmc_cart .snipper-body .left-side .mmc_card .btn__validate.add-venue .txt-add-more {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 10px;
  position: relative;

  span {
    margin-left: 0px !important;
  }
}

.header__user {
  @media screen and (max-width: 1024px) {
    // .user {
    //   height: 130px !important;
    // }
  }
}

// @media (max-width: 1024px) {
//   .header__alter-menu {
//     display: flex;
//     flex-direction: column !important;
//     justify-content: start;

//     .sub_left {
//       width: 100%;
//     }

//     .sub {
//       width: 100%;
//     }
//   }
// }

.file-drop{

  .drop-zone{
      .ngx-file-drop__content{
          div:nth-child(4){
              display: none;
          }
      }
          input.btn{
              display: none !important;
          }
          .ngx-file-drop__browse-btn {
              display: none;
          }
  }
}

.header__alter-menu .sub_left .wm-search_customer:before, .header__alter-menu .sub_left .wm-new_customer:before, .header__alter-menu .sub_left .wm-go-home:before, .wm-login-user-profile-icon-wrapper .wm-signIn-user-icon:before, .wm-login-user-profile-icon-wrapper .wm-dashboard-icon:before{
  border: none !important;
  height: 25px !important;
  width: 25px !important;
  font-size: larger !important;
}
.header__user .header-nav-venue:before, 
.header__user .header-nav-rb:before,
.wm-login-user-profile-icon-wrapper .wm-signIn-user-icon:before {
  background-color: #c9da43 !important;
  color: #000 !important;
  border: none !important;
  height: 25px;
  width: 25px;
  font-size: larger !important;
}
.header__alter-menu .sub_left .wm-search_customer, .header__alter-menu .sub_left .wm-new_customer, .header__alter-menu .sub_left .wm-selected-customer-profile, .header__alter-menu .sub_left .wm-go-home, .wm-login-user-profile-icon-wrapper .wm-signIn-user-icon, .wm-login-user-profile-icon-wrapper .wm-dashboard-icon{
  height: auto !important;
  color: #2574A9 !important;
}
.edit-booking-modal .estimated-price-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
}
.main_search .facility-search-input-cage {
  flex: 0 0 35%;
}
.setup-customer{
  text-indent: -9999px;
  line-height: 0 !important;
}
.tab-text.corp-customer{
  text-indent: -9999px;
  line-height: 0 !important;
}
.setup-customer::after{
  content: 'Are you an Organisation?';
  text-indent: 0;
  display: block;
  line-height: initial;
  text-transform: unset;
  text-align: center;
  width: 309px;
}
.tab-setup::after {
  content: 'Organisation Customer';
  text-indent: 0;
  display: block;
  line-height: initial;
  text-transform: unset;
  text-align: center;
}
.footer-contact-detail{
  line-height: 50px !important;
}

//print styles
@media print{
  *, *::after, *::before, *:first-letter, *:first-line {
    color: unset !important;
  }

  .snow.nbc .iframe-form{
    min-height: 15cm !important;
  }

  .user__cart .user__counter{
    background-color: #044fa2 !important;
  }

  .header__wrap{
    height: 4cm !important;
    .site-logo.site-logo-header {
      display: block;
      width: 8cm !important;
      img{
        margin-left: 0px;
        margin-top: 0cm
      }
    }
  }
  .payment{
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .header__wrap h1{
    width: 20cm !important;
    overflow: hidden;
  }
  .site-logo.site-logo-header {
      overflow: hidden;
      width: 8cm !important;
      height: auto !important;
      max-height: 4cm !important;
      img{
        margin-top: 2rem !important;
        margin-left: 1rem !important;
      }
  }

  .mat-step-header .mat-step-icon{
    background-color: rgba(0,0,0,.54) !important;
    color: white !important;
  }

  .header {
    background: black !important;
    height: 140px !important;
    padding: 0px 50px !important;
  }
  .header h1 {
      padding-bottom: 0px !important;
  }
  // .footer p {
  //   color: white !important;
  // }
  .footer a {
    color: #fff !important;
  }
  .footer-contact-detail{
    line-height: 50px !important;
  }
  .region.region-bottom {
    background: black !important;
  }
  .header--council{
    background: black !important;
  }
  .header__alter-menu .sub{
    display: flex;
    justify-content: flex-end !important;
  }
  
  .header__user .user{
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.11) !important;
    border: 1px solid #d9d9d9 !important;
    // background-color: #eaeaea !important;
  }
  .header__alter-menu {
    display: block !important;
  }
  html, body {
      height:100%; 
      color: unset !important;
      margin: 0 !important; 
      padding: 0 !important;
      overflow-x:hidden !important;
      page-break-after: avoid;
      overflow-y: hidden !important;
  }

  .header__links{
    display: none;
  }
  
  .header__actions{
    display: none;
    
  }

  .booking-cart-header{
    .font__nt-available{
      color:#e12890 !important;
    }
  }
  
  .mmc_enquiry .seco-line--colored{
    background-color: #faf5dd !important;
  }
  .mmc_enquiry .bottom-line{
    background-color: #00a651 !important;

  }
  .mmc_enquiry .bottom-line .para{
    color: white !important;
  }
  
}
.wp-modal-wrapper.wp-message-modal .mmc-btn-secondary--small {
  color: white !important;
}

.site-logo.site-logo-header {
  display: block;
  width: 126px !important;
}

.btn__card.main:hover{
  background: #000000 !important;
  border-color: #000;
}

.nbc .header-hamburger-icon {
  margin-right: 0px !important;
}

.user__left .user__account {
  margin-left: 10px !important;
}


.header__alter-menu .sub.wm-top-name .wm-sign-in-btn .ng-star-inserted {
  width: auto !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 8ch !important;

  @media screen and (max-width:768px) {
    margin-bottom: 0.7rem;
  }
}

.section-title {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: unset !important;
  word-wrap: break-word !important;
  max-width: 100% !important;
}


// NBC payment b-point
.snow.nbc .iframe-form {   
  @include respond-to("x-small") {
    min-height: 68rem !important;
  }
  @include respond-to("small") {
    min-height: 57rem !important;
  }
  @include respond-to("medium") {
    min-height: 58rem !important;
  }
  @include respond-to("large") {
    min-height: 59rem !important;
  }
  @include respond-to("x-large") {
    min-height: 59rem !important;
  }
  
  @media print{
    min-height: 15cm !important;
  }
}


@media screen and (min-width: 1024px){
  .snow.nbc .payment-iframe{
      height: 400px !important;
  }
}

@media screen and (max-width: 1024px){
  .snow.nbc .payment-iframe{
      height: 400px !important;
  }
}

@media screen and (max-width: 375px){
  .snow.nbc .payment-iframe{
      height: 390px !important;
  }
}

.mmc_flex--container.edit-timeSlot.category-event-selection-row.ng-star-inserted {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}

.booking-document-upload .pw-txt-h3 .pw-text-color__quinary {
  color: $opt-color_black !important;
}
.document-upload .pw-txt-h2 .pw-text-color__quinary {
  color: $grey-shade-1 !important;
}

@media screen and (max-width: 786px) {
  .search--result .btn__card.main {
    width: 86%;
  }
}

@media screen and (max-width: 1023px) {
  .pw-txt-b2.pw-text-color__quinary.venueSec {
    color: $opt_color_white !important;
  }
}


