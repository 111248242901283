@import "../variables";

$wm-text--color : $font-color;
$wm-border--color : $opt-border-color;


.wm {
    &-dialog-header {

		@include respond-to('x-small') {
			min-width: 0px;
		  }

		  @include respond-to('small') {
			min-width: 0px;
		  }
		
		  @include respond-to('medium') {
			min-width: 0px;
		  }
		
		  @include respond-to('large') {
			border-bottom: 1px solid $wm-border--color  !important;
			height: 60px;
			margin-top: -24px;
			margin-left: -24px;
        	margin-right: -24px;
        	min-width: 1000px;
		  }
		
		.icon {
			height: 60px;
			flex: 60px 0 0;
			background-color: #ffd402;
			.material-icons {
				font-size: 30px;
				margin-left: 14px;
				margin-top: 15px;
			}
		}
		.title {

			@include respond-to('x-small') {
				display: flex;
				flex-direction: column;
				align-items: end;
			  }
	
			  @include respond-to('small') {
				display: flex;
				flex-direction: column;
				align-items: end;
			  }
			
			  @include respond-to('medium') {
				flex-direction: column;
				align-items: end;
			  }
			
			  @include respond-to('large') {
				flex: 70 0 auto;
				height: 60px;
				padding-left: 20px;
				flex-direction: row;
			  }

			
			h2 {
				margin: 2px 0px 1px 0px;
				align-self: center;
			}
		}
        .status-title {

			@include respond-to('x-small') {
				align-self: end;
			  }
	
			  @include respond-to('small') {
				align-self: end;
			  }
			
			  @include respond-to('medium') {
				align-self: end;
			  }
			
			  @include respond-to('large') {
				flex: 15 0 auto;
				text-align: center;
				font-size: 16px;
				font-weight: 600;
				align-self: center;
			  }

           
        }
        &-msg-txt{			
            &--danger{
                color : $opt_status_cancelled;
            }
            &--warning{
                color : $opt_status_warning;
            }
            &--succsess{
                color : $opt_status_confirmed ;
            }
            &--info{
                color : $opt_status_good_availability;
            }
            
        }
		.btn-cage {
			flex: 15 0 auto;
			height: 60px;
			text-align: right;
			.material-icons {
				font-size: 30px;
				margin-right: 20px;
				margin-left: 14px;
				margin-top: 15px;
				cursor: pointer;
			}

		}

	}
    
}

.mat-form-field {
    width: 100% !important;

}

.wm-booking-history {
	height: 61vh !important;
	.wm-rec-count {
		align-items: center;
      	justify-content: start;
      	display: flex;
	}
	.wm-rec-search {
		justify-content: flex-end;
		display: flex;
		  mat-form-field {
			width: 250px !important;
		  }
	}
	.wm-item-rating {
		.wm-name {
			flex: 25% 0 0; 
			font-weight: 600;
		}
		.wm-date {
			flex: 25% 0 0;
		}
	}
}

