.wm-custom-modal-wrapper{
  width: 100% !important;
  .wm-header{
    justify-content: space-between;

  .sub-header-left , .sub-header-right{
    display: flex;
    align-items: center;

    ._icon-carge{
      padding: 10px 25px;
      background-color: #ffc107;
      margin-right: 25px;
      .material-icons{
        font-size: 45px !important;
      }
      .wm-client-search-icon{
        position: relative;
        top: 8px;
      }
    }
    .left-header-title{
       span{
        font-size: 20px;
        font-weight: 600;
       }
    }
    .booking-ref{
      font-size: 20px;
      text-transform: uppercase;
      color: blue;
      font-weight: 600;
    }
  }
 }
 .mmc-modal-body{
   overflow: hidden !important;
   margin: 2% 3%;

   .f-20{
     font-size: 18px;
     color: #525050;
   }

   .refund-condition{
    font-size: 12px;
    margin-bottom: 13px;
   }
   .refund-due{

    font-size: 25px;
    font-family: 'Montserrat' !important;
    color: #525050;
    font-weight: 600;
   }
 }
 .wm-client-search-body{
   padding:15px;
 }
 .mmc-modal-footer{
   .wm-refund-note{
    margin-left: 12px;
    font-size: 14px;
   }

 }
}

.wm-cancel-paid-booking-modal{
  overflow: hidden;
}


.wm-cancel-container {
  @include respond-to('x-small') {
    min-width: 300px !important;
  }

  @include respond-to('small') {
    min-width: 600px !important;
  }

  @include respond-to('medium') {
    min-width: 590px !important;
  }

  @include respond-to('large') {
    min-width: 1000px !important;
  }
}
