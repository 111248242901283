﻿html,
        *,
        *::before,
        *::after {
            box-sizing: border-box
        }

        fieldset,
        iframe,
        img,
        legend {
            border: 0
        }

        h1,
        h2,
        h3,
        h5,
        h6,
        keygen {
            font-size: 100%
        }

        menu,
        ol,
        ul {
            list-style-type: none
        }

        blockquote,
        body,
        dd,
        dl,
        fieldset,
        figure,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        hr,
        menu,
        ol,
        p,
        pre,
        ul {
            margin: 0
        }

        fieldset,
        legend,
        menu,
        ol,
        ul {
            padding: 0
        }

        audio,
        canvas,
        iframe,
        img,
        svg,
        video {
            vertical-align: middle
        }

        @viewport {
            width: device-width
        }

        html {
            color: #000;
            font-family: "Muli", Helvetica Neue, Helvetica, Arial, sans-serif;
            height: 100%;
            line-height: 1.375
        }

        p,
        ol,
        ul,
        blockquote,
        table {
            margin-bottom: 1.5em;
            line-height: 1.5em
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: .5em;
            font-weight: 300;
            letter-spacing: -.01em
        }

        h1 {
            font-size: 2.5em
        }

        h2 {
            font-size: 2em
        }

        h3 {
            font-size: 1.25em
        }

        h4 {
            font-size: 1em
        }

        h5 {
            font-size: .875em
        }

        h6 {
            font-size: .65em
        }

        a {
            color: #000;
            text-decoration: underline
        }

        a:focus,
        a:hover {
            text-decoration: none
        }

        .body-wrap {
            padding-top: 5em
        }

        .has-alert .body-wrap {
            padding-top: 10em
        }

        @media screen and (min-width:48em) {
            .body-wrap {
                padding-top: 8.25em
            }
            .ie10 .subsite-glen-street-theatre .body-wrap {
                padding-top: 5.5em
            }
            .subsite-glen-street-theatre .body-wrap {
                padding-top: 5em
            }
            .has-alert .body-wrap {
                padding-top: 12.5em
            }
        }

        .container {
            margin-left: auto;
            margin-right: auto;
            max-width: 78.5em;
            position: relative;
            width: 93.75%
        }

        #block-views-gi-fields-rev-images,
        #block-views-ra-fields-rev-photos {
            margin-bottom: 2em
        }

        #block-views-gi-fields-rev-images .block__title,
        #block-views-ra-fields-rev-photos .block__title {
            display: none
        }

        #block-views-gi-fields-rev-images .flexslider,
        #block-views-ra-fields-rev-photos .flexslider {
            padding: 0;
            margin: 0;
            border: none
        }

        #block-views-gi-fields-rev-images a,
        #block-views-ra-fields-rev-photos a {
            display: block
        }

        #block-views-gi-fields-rev-images img,
        #block-views-ra-fields-rev-photos img {
            display: block;
            width: 100%;
            height: auto
        }

        #block-views-sub-page-listing-block .block__title {
            font-size: 1.5em;
            margin-bottom: .75em
        }

        #block-views-sub-page-listing-block .block-content {
            background: #eee;
            padding-bottom: 1em
        }

        .view-sub-page-listing .views-row {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding: 1em
        }

        .view-sub-page-listing .views-row:last-child {
            border-bottom: none;
            padding-bottom: 0
        }

        .view-sub-page-listing .listing-summary {
            margin-right: 1em;
            width: 66%
        }

        .view-sub-page-listing .listing-thumbnail {
            width: 33%;
            order: 2
        }

        .view-sub-page-listing .listing-thumbnail+.listing-thumbnail {
            display: none
        }

        .view-sub-page-listing .listing-thumbnail a {
            display: block
        }

        .view-sub-page-listing .listing-thumbnail img {
            width: 100%;
            height: auto;
            display: block
        }

        .view-sub-page-listing h3 {
            font-size: 1.15em;
            margin-bottom: .25em;
            font-weight: bold
        }

        .view-sub-page-listing h3 a {
            text-decoration: none
        }

        .view-sub-page-listing h3 a:hover {
            text-decoration: underline
        }

        .view-mode-splash_page .view-sub-page-listing .views-row {
            display: inline-flex;
            width: 33%;
            opacity: 1;
            mix-blend-mode: normal;
            padding: 0px;
            border-width: 0px;
            border-radius: 2px;
            border-color: #666;
            border-style: solid;
            background-color: #fff;
            background-image: none;
            background-size: cover;
            background-repeat: repeat;
            background-position: center center;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px 0px;
            min-height: 400px
        }

        .view-mode-splash_page .view-sub-page-listing .listing-summary {
            margin-right: 1em;
            width: 100%;
            padding: 24px
        }

        .view-mode-splash_page .view-sub-page-listing .listing-thumbnail {
            width: 100%;
            order: 2;
            overflow: hidden
        }

        .view-mode-splash_page .paragraphs-items-full {
            background-color: #f7f7f7
        }

        .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content {
            width: 33%;
            display: inline-block;
            float: left
        }

        .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content .info-block-pink {
            border-left-color: #e12890
        }

        .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content .info-block-green {
            border-left-color: #cada43
        }

        .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content .info-block-blue {
            border-left-color: #044fa2
        }

        .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content .info-block-blue,
        .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content .info-block-green,
        .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content .info-block-pink {
            border-left-style: solid;
            padding: 0px 20px 0px
        }

        .view-mode-splash_page .paragraphs-items-full .info,
        .view-mode-splash_page .paragraphs-items-full .carousel-item {
            width: 50%;
            display: inline-block;
            float: left
        }

        .view-mode-splash_page .paragraphs-items-full .info {
            padding-bottom: 1.5em
        }

        .ds-header {
            padding: 0em 1.5em 1.5em
        }

        .ds-header .field-name-title h2 {
            color: #006e62;
            font-size: 2em;
            font-weight: normal;
            font-style: normal
        }

        @media screen and (min-width:48em) {
            .ds-header .field-name-title h2 {
                font-size: 4.5em
            }
        }

        .ds-header .field-name-field-gi-subtitle {
            color: #00947f;
            font-size: 1.25em;
            font-weight: normal;
            font-style: normal;
            padding-bottom: 0.5em
        }

        @media screen and (min-width:48em) {
            .ds-header .field-name-field-gi-subtitle {
                font-size: 2.5em
            }
        }

        @media screen and (max-width:64em) {
            .view-mode-splash_page .view-sub-page-listing .views-row {
                width: 50%
            }
            .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content {
                width: 50%
            }
            .view-mode-splash_page .paragraphs-items-full .info,
            .view-mode-splash_page .paragraphs-items-full .carousel-item {
                width: 100%
            }
        }

        @media screen and (max-width:37.4375em) {
            .view-mode-splash_page .view-sub-page-listing .views-row {
                width: 100%
            }
            .view-mode-splash_page .paragraphs-items-full .field-name-field-par-content {
                width: 100%
            }
            .view-mode-splash_page .paragraphs-items-full .info,
            .view-mode-splash_page .paragraphs-items-full .carousel-item {
                width: 100%
            }
        }

        .section-splash-page .main {
            background: #eee
        }

        .section-splash-page .main .container {
            background: #fff
        }

        .section-splash-page .main__page {
            overflow: hidden
        }

        .section-splash-page .main__page .page-wrapper {
            width: 120%;
            overflow: hidden
        }

        @media screen and (max-width:85.375em) {
            .section-splash-page .main__page .page-wrapper {
                max-height: 686px
            }
        }

        @media screen and (max-width:64em) {
            .section-splash-page .main__page .page-wrapper {
                max-height: 500px
            }
        }

        @media screen and (max-width:47.9375em) {
            .section-splash-page .main__page .page-wrapper {
                max-height: 380px
            }
        }

        @media screen and (max-width:37.4375em) {
            .section-splash-page .main__page .page-wrapper {
                max-height: 203px
            }
        }

        .breadcrumb {
            margin-bottom: 1.5em
        }

        .breadcrumb ol {
            display: flex;
            flex-flow: wrap;
            margin-bottom: 0
        }

        .breadcrumb ol li {
            margin-right: .25em;
            color: #ccc
        }

        .breadcrumb ol li:last-child {
            font-weight: bold;
            color: #000
        }

        .breadcrumb ol li a {
            font-weight: normal;
            text-decoration: none;
            color: #000
        }

        .breadcrumb ol li a:hover {
            text-decoration: underline
        }

        .featured-search {
            display: none
        }

        .node-type-homepage .flexslider {
            border: none;
            margin: 0;
            height: 23em
        }

        @media screen and (min-width:48em) {
            .node-type-homepage .flexslider {
                height: 26em
            }
        }

        .node-type-homepage .flexslider .flex-viewport,
        .node-type-homepage .flexslider .flexslider-views-slideshow-main-frame,
        .node-type-homepage .flexslider .flexslider_views_slideshow_slide,
        .node-type-homepage .flexslider .slides {
            height: 100%
        }

        .node-type-homepage .flexslider .flexslider_views_slideshow_slide,
        .node-type-homepage .flexslider .slide {
            position: relative;
            height: 100%
        }

        .node-type-homepage .flexslider .carousel-item {
            height: 100%;
            background-position: center center;
            background-size: cover
        }

        .node-type-homepage .flexslider .carousel-item img {
            object-fit: cover;
            width: 100%;
            height: 100%
        }

        .node-type-homepage .flexslider .carousel-item .info {
            position: absolute;
            top: 50%;
            left: 50%;
            background: #fff;
            color: #000;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 2em;
            transform: translate(-50%, -50%);
            width: calc(100% - 2em);
            max-width: 35em
        }

        @media screen and (min-width:48em) {
            .node-type-homepage .flexslider .carousel-item .info {
                width: auto;
                left: auto;
                right: 0;
                transform: translatey(-50%);
                align-items: flex-start;
                text-align: left;
                padding: 2em 3em
            }
        }

        .node-type-homepage .flexslider .carousel-item .info h2 {
            font-size: 1.75em;
            font-weight: 300;
            margin-bottom: 0;
            line-height: 1.125em;
            color: #007cb8
        }

        @media screen and (min-width:48em) {
            .node-type-homepage .flexslider .carousel-item .info h2 {
                font-size: 2.5em
            }
        }

        .node-type-homepage .flexslider .carousel-item .info .field-name-field-component-description {
            color: #40c8f4;
            font-size: 1.5em;
            line-height: 1.125em;
            font-weight: 300;
            margin-bottom: 0
        }

        @media screen and (min-width:48em) {
            .node-type-homepage .flexslider .carousel-item .info .field-name-field-component-description {
                font-size: 2.5em
            }
        }

        .node-type-homepage .flexslider .carousel-item .info .field-name-field-component-description p {
            margin-bottom: 0;
            line-height: 1.25em
        }

        .node-type-homepage .flexslider .carousel-item-alt {
            position: static;
            display: block;
            text-decoration: none
        }

        .node-type-homepage .flexslider .carousel-item-alt div.carousel-item {
            height: auto
        }

        .node-type-homepage .flexslider .carousel-item-alt .slide-background {
            height: 14em
        }

        .node-type-homepage .flexslider .carousel-item-alt .info {
            position: static;
            display: flex;
            text-align: left;
            align-items: flex-start;
            transform: none;
            width: 100%;
            max-width: none;
            padding: 1em 2em 4em 1em;
            background: #fff
        }

        .node-type-homepage .flexslider .carousel-item-alt .info h2 {
            font-size: 1.5em
        }

        .node-type-homepage .flexslider .slides>li {
            display: none
        }

        .node-type-homepage .flexslider .slides>li:first-child {
            display: inherit
        }

        .flex-control-nav,
        .flex-direction-nav,
        .flex-pauseplay {
            display: none
        }

        .footer {
            display: none
        }

        .header {
            background: #000;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 8.25rem;
            z-index: 11
        }

        .header__links,
        .header__wrap {
            display: none
        }

        .main__sidebar-first {
            visibility: hidden;
            width: 100%;
            order: 3
        }

        @media screen and (min-width:64.0625em) {
            .main__sidebar-first {
                width: calc(25% - 1em);
                order: 1
            }
        }

        .main__content {
            display: flex;
            flex-direction: column;
            flex-flow: wrap
        }

        @media screen and (min-width:64.0625em) {
            .main__content {
                flex-direction: row
            }
        }

        .not-front .main__content {
            padding-top: 2em;
            padding-bottom: 2em
        }

        .node-type-homepage.subsite-library .main__content {
            padding-top: 0;
            padding-bottom: 0
        }

        .main__header {
            width: 100%
        }

        .node-type-homepage .main__header {
            display: none
        }

        .main__page {
            width: 100%;
            order: 2
        }

        .main__page .page-user #user-login {
            max-width: 30em;
            margin: 0 auto
        }

        @media screen and (min-width:64.0625em) {
            .has-first-sidebar .main__page {
                width: calc(75% - 1em);
                margin-left: 1.5em
            }
        }

        @media screen and (min-width:64.0625em) {
            .has-second-sidebar .main__page {
                width: calc(75% - 1em);
                margin-left: 1.5em
            }
        }

        @media screen and (min-width:64.0625em) {
            .has-second-sidebar.has-first-sidebar .main__page {
                width: calc(55% - 3em);
                margin-left: 1.5em;
                margin-right: 1.4em
            }
        }

        .not-front .main__page>h2.element-invisible:first-child+.tabs-primary {
            margin-top: 0
        }

        .main__page .block {
            margin-bottom: 1.5em
        }

        .main__page .block:last-child {
            margin-bottom: 0
        }

        .page-user .main__page {
            padding-bottom: 2em
        }

        #component--whats-on-teaser-council,
        #component--whats-on-teaser {
            background: #000 url(https://files.northernbeaches.nsw.gov.au/sites/all/themes/nbc/images/whats-on-bg.jpg) top right no-repeat;
            background-size: 75%;
            padding: 2em 0;
            width: 100%;
            max-width: 100%
        }

        .node-type-homepage.section-library #component--whats-on-teaser-council,
        .node-type-homepage.section-library #component--whats-on-teaser {
            background: #000 url(https://files.northernbeaches.nsw.gov.au/sites/all/themes/nbc/images/library-events-bg.jpg) top right no-repeat;
            background-size: 65%
        }

        .subsite-glen-street-theatre #component--whats-on-teaser-council,
        .subsite-glen-street-theatre #component--whats-on-teaser {
            background: #000 url(https://files.northernbeaches.nsw.gov.au/sites/all/themes/nbc/images/glen-whatson-bg.jpg) top right no-repeat;
            background-size: 65%
        }

        #component--whats-on-teaser-council .field-name-field-component-description,
        #component--whats-on-teaser .field-name-field-component-description {
            color: #fff;
            text-align: center
        }

        #component--whats-on-teaser-council .field-name-field-component-link,
        #component--whats-on-teaser .field-name-field-component-link {
            text-align: center;
            margin-top: 2em
        }

        #component--whats-on-teaser-council .field-name-field-component-link a,
        #component--whats-on-teaser .field-name-field-component-link a {
            background: url(https://files.northernbeaches.nsw.gov.au/sites/all/themes/nbc/images/icons/button-arrow-fff.png) top 50% right 0% no-repeat !important;
            background-size: 1em 1em;
            color: #fff !important
        }

        #component--whats-on-teaser-council>h2,
        #component--whats-on-teaser>h2 {
            margin-bottom: 0
        }

        #component--whats-on-teaser-council>p,
        #component--whats-on-teaser>p {
            margin-bottom: 1em
        }

        #component--whats-on-teaser-council>h2,
        #component--whats-on-teaser-council>p,
        #component--whats-on-teaser>h2,
        #component--whats-on-teaser>p {
            color: #fff;
            text-align: center
        }

        .whats-on-teaser__header {
            color: #fff;
            text-align: center
        }

        .whats-on-teaser__header p {
            margin-bottom: 0
        }

        .whats-on-teser__list {
            margin-bottom: 0;
            margin-top: 2em
        }

        .whats-on-teaser__item {
            float: left;
            width: 100%;
            margin-bottom: .5em
        }

        .whats-on-teaser__item .whats-on-teaser__wrap:focus .whats-on-teaser__hover,
        .whats-on-teaser__item .whats-on-teaser__wrap:hover .whats-on-teaser__hover {
            opacity: 1
        }

        .whats-on-teaser__item .whats-on-teaser__wrap:focus .whats-on-teaser__info,
        .whats-on-teaser__item .whats-on-teaser__wrap:hover .whats-on-teaser__info {
            opacity: 0
        }

        @media screen and (min-width:48em) {
            .whats-on-teaser__item {
                margin: 0 .25em .5em;
                width: calc(25% - .5em)
            }
            .whats-on-teaser__item:first-child {
                width: calc(50% - .5em)
            }
            .whats-on-teaser__item:first-child .whats-on-teaser__wrap {
                padding-top: calc(56.25% + .25em)
            }
        }

        .whats-on-teaser__item:first-child .whats-on-teaser__info {
            width: auto;
            text-align: left
        }

        .whats-on-teaser__wrap {
            display: block;
            height: 0;
            padding-top: 56.25%;
            position: relative;
            position: relative;
            background-position: center center;
            background-size: cover
        }

        .whats-on-teaser__wrap>* {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%
        }

        .whats-on-teaser__info {
            background: #fff;
            color: #000;
            position: absolute;
            padding: 0 0.5em;
            bottom: 0;
            left: 0;
            top: auto;
            height: 4em;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s;
            width: auto
        }

        .whats-on-teaser__info span {
            line-height: 1.5em;
            text-align: center;
            font-size: .75em;
            font-weight: bold
        }

        .whats-on-teaser__hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.85);
            align-items: center;
            justify-content: center;
            color: #fff;
            opacity: 0;
            display: flex;
            transition: all .2s;
            padding: 1em;
            text-align: center
        }

        @media screen and (max-width:64em) {
            .whats-on-teaser__hover {
                background: white;
                color: #000;
                position: absolute;
                height: 4em;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .2s;
                background: rgba(0, 0, 0, 0.85);
                color: #fff;
                position: absolute;
                height: 4em;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .2s;
                font-size: 0.65em;
                opacity: 1
            }
        }

        @media screen and (max-width:37.4375em) {
            .whats-on-teaser__hover {
                font-size: 0.75em
            }
        }

        .whats-on-teaser__button {
            padding-top: 1em;
            text-align: center
        }

        .section-council-about-northern-beaches-council-extraordinary-tales .view-news .node__title {
            margin-bottom: .5em
        }

        .section-council-about-northern-beaches-council-extraordinary-tales .view-news .date-event {
            display: none
        }

        .view-latest-events .whats-on-teaser__info {
            line-height: 1.5em;
            text-align: center;
            font-size: .75em;
            font-weight: bold
        }

        .field-name-field-icon-list {
            display: flex;
            flex-flow: wrap;
            margin-bottom: 1.5em;
            text-align: center;
            justify-content: center
        }

        .field-name-field-icon-list>li {
            width: 50%
        }

        .field-name-field-icon-list>li:nth-of-type(1n+7) {
            display: none !important
        }

        @media screen and (min-width:48em) {
            .field-name-field-icon-list>li {
                width: 16.666%
            }
        }

        .paragraphs-item-icon-list {
            padding-top: 2em;
            text-align: center;
            margin-bottom: 2em
        }

        .paragraphs-item-icon-list>h2 {
            margin-bottom: 0
        }

        .paragraphs-item-icon-list .button {
            background-image: none;
            padding-right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto
        }

        .paragraphs-item-icon-list .button::after {
            content: "";
            display: block;
            width: 1em;
            height: 1em;
            background: url(https://files.northernbeaches.nsw.gov.au/sites/all/themes/nbc/images/icons/button-arrow-down.svg) no-repeat;
            background-size: 1em 1em;
            margin-left: 1em;
            transition: all .2s
        }

        .paragraphs-item-icon-list.is-active li {
            display: block !important
        }

        .ie10 .paragraphs-item-icon-list.is-active li {
            display: inline-block !important
        }

        .paragraphs-item-icon-list.is-active .button::after {
            transform: rotate(180deg)
        }

        .paragraphs-item-icon-list-item>a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-decoration: none;
            padding: 1.5em 0
        }

        .paragraphs-item-icon-list-item>a:hover svg {
            fill: #007cb8
        }

        .paragraphs-item-icon-list-item>a:hover .svg-icon-request svg {
            stroke: #007cb8
        }

        .paragraphs-item-icon-list-item>a:hover .svg-icon-bins svg,
        .paragraphs-item-icon-list-item>a:hover .svg-icon-more-info svg,
        .paragraphs-item-icon-list-item>a:hover .svg-icon-reduce svg,
        .paragraphs-item-icon-list-item>a:hover .svg-icon-bulky-goods svg {
            stroke: #007cb8
        }

        .paragraphs-item-icon-list-item .list-item__title {
            color: #000;
            font-weight: 300;
            font-size: 1em
        }

        .paragraphs-item-icon-list-item .svg-icon {
            margin-bottom: 1em
        }

        .paragraphs-item-icon-list-item .svg-icon.svg-icon-request svg {
            stroke: #000
        }

        .paragraphs-item-icon-list-item .svg-icon svg {
            width: 1em;
            height: 1em;
            font-size: 2.5em;
            fill: #000;
            transition: fill .2s
        }

        #component--waste-mgmt .svg-icon-bin svg {
            height: 3em;
            width: 0.8em
        }

        #component--waste-mgmt .svg-icon-bins svg,
        #component--waste-mgmt .svg-icon-more-info svg,
        #component--waste-mgmt .svg-icon-reduce svg,
        #component--waste-mgmt .svg-icon-bulky-goods svg {
            height: 3em;
            width: 3em;
            stroke-width: 1
        }

        #component--waste-mgmt .button {
            display: none
        }

        #component--waste-mgmt .field-name-field-icon-list>li {
            width: 100%
        }

        #component--waste-mgmt .field-name-field-icon-list>li:nth-of-type(1n+7) {
            display: none !important
        }

        @media screen and (min-width:48em) {
            #component--waste-mgmt .field-name-field-icon-list>li {
                width: 25%
            }
        }

        #component--waste-mgmt .paragraphs-items-field-icon-list {
            border-bottom: solid 1px
        }

        .js-tablist {
            visibility: hidden
        }

        .paragraphs-items-field-columns {
            display: none
        }

        .view-gst-events-fields-rev .service-links .service-label {
            width: 100%
        }

        .service-links {
            display: flex !important;
            flex-flow: wrap;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            padding: 1em 0;
            align-items: center;
            margin-bottom: 1em;
            justify-content: flex-end
        }

        .service-links .service-label {
            margin-right: 1em;
            font-weight: bold
        }

        .ie10 .service-links .service-label {
            float: left
        }

        .service-links .links {
            display: flex;
            flex-flow: wrap;
            margin-bottom: 0
        }

        .service-links .links>li {
            margin-right: .5em
        }

        .service-links .links>li:empty {
            margin-right: 0
        }

        .ie10 .service-links .links>li {
            float: left
        }

        .service-links .links>li a {
            display: block
        }

        .service-links .links>li a svg {
            width: 1.5em;
            height: 1.5em;
            transition: fill .2s
        }

        .service-links .links>li a:hover svg {
            fill: #007cb8
        }

        #skip-link a {
            position: absolute !important;
            top: 0;
            left: 0;
            padding: 1em !important;
            background: #fff;
            z-index: 99;
            display: block;
            text-decoration: none
        }

        #skip-link a:hover {
            text-decoration: underline
        }

        .section-glen-street-theatre-whats-on .main__sidebar-second .block {
            margin-bottom: 1.5em
        }

        .section-glen-street-theatre-whats-on .main__sidebar-second .block#block-views-cmn-fields-rev-pricing .view-cmn-fields-rev .view-content>div {
            border-top: none !important;
            padding-bottom: 0
        }

        .section-glen-street-theatre-whats-on .main__sidebar-second .block .block__title {
            font-size: 1.5em;
            margin-bottom: .125em
        }

        .section-glen-street-theatre-whats-on .main__sidebar-second .service-links {
            justify-content: flex-start;
            border-bottom: none;
            padding-top: 2em
        }

        .section-glen-street-theatre-whats-on .main__sidebar-second .service-links .service-label {
            margin-bottom: 1em
        }

        .section-glen-street-theatre-whats-on .node-gste.view-mode-full .booking-buttons-bottom {
            margin-top: 1em
        }

        @media screen and (min-width:48em) {
            .section-glen-street-theatre-whats-on .node-gste.view-mode-full {
                display: flex;
                flex-flow: wrap
            }
            .section-glen-street-theatre-whats-on .node-gste.view-mode-full>div {
                width: 100%
            }
            .section-glen-street-theatre-whats-on .node-gste.view-mode-full>h3 {
                width: 50%
            }
            .section-glen-street-theatre-whats-on .node-gste.view-mode-full .field-label {
                font-weight: bold
            }
            .section-glen-street-theatre-whats-on .node-gste.view-mode-full .booking-buttons-top {
                width: 50%;
                display: flex;
                justify-content: flex-end
            }
        }

        .container::after,
        .whats-on-teser__list::after,
        .service-links::after,
        .ie10 .service-links .links::after,
        .u-clearfix::after {
            clear: both;
            content: "";
            display: table
        }

        .u-hide-text {
            overflow: hidden;
            text-indent: 100%;
            white-space: nowrap
        }

        .u-hyphenate {
            hyphens: auto;
            overflow-wrap: break-word;
            word-wrap: break-word
        }

        a,
        .u-transition {
            transition-duration: 0.1s;
            transition-property: background-color, background-image, border-color, box-shadow, color, opacity, text-shadow;
            transition-timing-function: ease-out
        }

        #skip-link a,
        .u-visually-hidden,
        .element-invisible {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px
        }

        #skip-link a:active,
        #skip-link a:focus,
        .u-visually-hidden--focusable:active,
        .u-visually-hidden--focusable:focus,
        .element-focusable:active,
        .element-focusable:focus {
            clip: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            position: static;
            width: auto
        }
