@import "../variables";

$wm-text--color : $font-color;
$wm-border--color : $opt-border-color;


.wm-dialog-header{
    @include respond-to('x-small') {
        min-width: 0px;
                         
    }
    @include respond-to('small') {
        min-width: 0px;
      
    }
  
    @include respond-to('medium') {
        min-width: 0px;
                             
    }
  
    @include respond-to('large') { 
        min-width: 1000px;  
                          
            
    }
    .mmc_flex--container{
        .wm-heading-mattress{
            @include respond-to('x-small') {
                align-self: end;
                             
            }
            @include respond-to('small') {
                align-self: end;
              
            }
          
            @include respond-to('medium') {
                align-self: center;
                                     
            }
          
            @include respond-to('large') {    
                align-self: center;                 
                    
            }
        }
        .mmc_flex__item{
            
    @include respond-to('x-small') {

                         
    }
    @include respond-to('small') {
      
    }
  
    @include respond-to('medium') {
                             
    }
  
    @include respond-to('large') {                     
            
    }

        }
    }
    .material-icons{

    }


}
    .wm-booking-rate-body{

        @include respond-to('x-small') {      
            padding: 3px 0px;           
        }
        @include respond-to('small') {
            padding: 3px 0px; 
          
        }
      
        @include respond-to('medium') {
            padding: 5px 20px;
                                 
        }
      
        @include respond-to('large') {  
            padding: 5px 20px;                   
                
        }

        // display: flex;
        .wm-item-rating{

            @include respond-to('x-small') {

                         
            }
            @include respond-to('small') {
              
            }
          
            @include respond-to('medium') {
                                     
            }
          
            @include respond-to('large') {                     
                    
            }
            display: flex;
            padding: 15px 0px;
            .wm-rating{
                flex: 50% 0 0;  
            }
            .we-icon{
                .wm-rating-poor{
                    font-size: 12px;
                    color: red;  
                }
            }
            .wm-rating-name{
                font-weight: 550;
            }
            .wm-colection-view-date{
                font-weight: 500;
            }
            .wm-rating-info{
                .wm-rating-info-two{
                    padding: 5px 0px;
                }
            }
        }
        .wm-item-rating-last{
            padding-top: 10px;
            font-size: 12px;
        }

        .wm-header-details-wrapper {
            @include respond-to('x-small') {
                margin: 0;
              }
    
              @include respond-to('small') {
                margin: 0;
              }
            
              @include respond-to('medium') {
                margin: 0;
              }
            
              @include respond-to('large') {
                display: flex;
                background-color: lightgray;
                margin: 0px 0px;
                padding: 13px 0;
                border-radius: 5px;
                margin-top: 5px;
              }
           

            .wm-location-name {
                padding: 4px 12px;

            }

        }
    }

// .wm-modal-header {
//     height: 85px !important;
// }