@import "../themes/iwc/variables.scss";


$main-btn-color : #D4007F !important;

// @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
// @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900");
// @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

// @import "../vendors/bootstrap/scss/bootstrap.scss";
//@import "~bootstrap/dist/css/bootstrap.css";
// @import "../vendors/bootstrap/scss/bootstrap.scss";


// @import "../helpers/functions.scss";
// @import "../helpers/mixins.scss";
// @import "../helpers/flexbox.scss";


// base
// @import "../base/color.scss";
// @import "../base/colors.scss";
// @import "../base/normalize.scss";
// @import "../base/tc-md-typography.scss";
// @import "../base/typography.scss";


// @import "../base/padding.scss";
// @import "../base/border.scss";
// @import "../base/margin.scss";
// @import "../base/_icon-font.scss";
// @import "../base/tc-md-color-definitions.scss";
// @import "../base/tc-md-functions.scss";
// @import "../base/tc-md-typography.scss";
// @import "../base/_tc-md-variables.scss";

@import "./misc";

@import "../components/_buttons.scss";

// PrivateWeb related sass gose hr
@import "./modals/wm-booking-rate";
@import "./modals/wm-cancel-booking-free";
@import "./modals/wm-mattress-colection-viewe";
@import "./modals/wm-missed-collection-modal";
@import "./modals/wm-modals-layout";
@import "./modals/wm-new-booking";
@import "./modals/wm-payment";
@import "./modals/wm-report-missed-collection";
@import "./modals/wm-star-rate-service";
@import "./modals/wm-cancle-booking-fee-update";
@import "./modals/wm-audit-history";

//components related sass
@import "./components/wm-booking-confirmation";
@import "./components/wm-cancel-paid-booking-modal";
@import "./components/wm-common-modal";
@import "./components/wm-image-upload.component";
@import "./components/wm-loader-spinner";
@import "./components/wm-new-waste-collection";
@import "./components/wm-package-detail-popup";
@import "./components/wm-payment-details";
@import "./components/wm-payment-method-modal";
@import "./components/wm-sub-header.component";
@import "./components/wm-term-and-conditions";
@import "./components/wm-waste-collection-status";
