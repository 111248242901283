
.wm-waste-collection-wrapper{
  .wm-status-card-list{
    //max-height: 696px;
    overflow: hidden;
  }
  .wm-show-more-pkg{
    float: right;
    padding-right: 10px;
    font-size: 13px;
    font-weight: bold;
  }
   .less-more{
    overflow: none;
   }
   .show-more{
    overflow-y: scroll;
   }

   .wm-previous-tab-section{
      //  max-height: 722px;

   }
   .wm-upcoming-tab-section{
      // max-height: 537px;
  }
  .wm-collection-status-spinner{
    text-align: center;
    margin-top: 50px;

    .internal-server-error-spinner{
      .material-icons{
        font-size: 90px !important;
        color: #e64040 !important;
      }
    }
    .internal-server-error-text{
      color:red;
    }
  }
  .wm-collection-spinner-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;

    .font__small{
      margin-top:35px;
    }
  }

  .wm-progress-bar{
        padding: 0px 5px;
     .mat-progress-bar-fill::after {
        background-color: #dab786 ;
     }
  }
  .wm-sub-check-collection-title{
    padding-left: 15px !important;
  }




}

