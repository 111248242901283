

   %header_nav-icon-cover{
     min-width: 50px;
     padding-right: 10px;
     display: flex;
     color: #787272;
     align-items: center;
     transition: 0.3s;
     text-decoration: unset !important;
   }
   %header_nav-icon-before{
     font-family: "Material Icons";
     transition: 0.3s;
     margin-right: 5px;
     background-color: #ffffff;
     color: #787272;
     border: 1px solid;
     border-radius: 50%;
     padding: 5px;
     height: 35px;
     width: 35px;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 25px;
   }

  %header_nav-hover{
     background-color: #787272;
     color: #fff;
  }


  .header__alter-menu{
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width:768px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
      .sub_left {
           .wm-search_customer {
              @extend %header_nav-icon-cover;
              margin-left: 20px;
              &:focus {
                outline: 2px solid black;
              }
             &:before {
               content: 'group';
               @extend %header_nav-icon-before;
             }

             &:hover {
               &:before {
                 @extend %header_nav-hover;
               }
             }
           }
        .wm-new_customer {

          @extend %header_nav-icon-cover;

          &:before {
            content: 'person_add';
            @extend %header_nav-icon-before;
          }

          &:hover {
            &:before {
              @extend %header_nav-hover;
            }
          }
        }
        .wm-selected-customer-profile{
             @extend %header_nav-icon-cover;

          //  &:before {
          //   content: 'person';
          //   padding-left: 7px;
          //   @extend %header_nav-icon-before;
          //  }

          &:hover {
            &:before {
              @extend %header_nav-hover;
            }
          }
        }
        .wm-go-home{
          width: 20ch;

          @extend %header_nav-icon-cover;

          &:before {
            content: 'home';
            @extend %header_nav-icon-before;
          }

          &:hover {
            &:before {
              @extend %header_nav-hover;
            }
          }
          &.wastebooking:after {
            @media only screen and (max-width: 768px) {
              content: '';
              padding-left: 10px;
            }
          }
        }
      }
      .sub_left {
        @media only screen and (max-width: 768px) {
          border-bottom: 1px solid #b7b7b7;
          min-height: 70px;
          width: 100% !important;
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 10px !important;
          padding-top: 10px;
        }
      }
      .sub {
        @media only screen and (max-width: 768px) {
          min-height: 70px;
          width: 100% !important;
          justify-content: space-between !important;
        }

        .justifyContent{
          justify-content: flex-end;
          width: 100%;
        }
      }
      
      // .width-limiter{
      //   @media (min-width: 769px){
      //     flex: 0 0 50% !important;
      //     max-width: 50% !important;
      //   }
      //   @media (max-width: 768px){
      //     flex: 0 0 100% !important;
      //     max-width: 100% !important;
      //   }
      // }
  }
  .wm-sign-in-btn{
    @media screen and (max-width:400px) {
      width: 88% !important;
      display: flex;
      justify-content: space-between;
    }

    button {
      display: flex;
      margin-right: 15px;
      width: 42px !important;
      min-width: unset;

      @media screen and (max-width:768px) {
        margin-right: 0px;
        justify-content: end !important;
      }
  }

    @media only screen and (max-width: 768px) {
      width: 80% !important;

      div:nth-child(1){
        width: 80%;
      }
      button{
        width: 45px;
        margin-right: -15px;
      }
      a {
        padding: 0 15px !important;
      }      
    }
      button i {
        margin: 0px !important;
        padding: 0px 12px;
    }

  }
  .wm-dashboard-icon-wrapper{
    margin-right: 15px !important;
  }
  .wm-login-user-profile-icon-wrapper{
     position: relative;
    //  left: 25px;
    width: 40px;

       .wm-signIn-user-icon{
          cursor: pointer;
          min-width: 45px !important;
          @extend %header_nav-icon-cover;

          &:before {
            content: 'person';
            @extend %header_nav-icon-before;
          }

          &:hover {
            &:before {
              @extend %header_nav-hover;
            }
          }
     }
     @media screen and (max-width: 768px) {
      .wm-signIn-user-icon {
        min-width: 40px !important;
      }
     }

    .wm-dashboard-icon{

      @extend %header_nav-icon-cover;
      cursor: pointer;
      &:before {
        content: 'apps';
        @extend %header_nav-icon-before;
      }

      &:hover {
        &:before {
          @extend %header_nav-hover;
        }
      }
    }

     .wm-profile-img{
          padding-right: 0px;
     }
     .wm-profile-img-internal {
      padding-right: 35px !important;
      pointer-events: none;
     }
     .wm-profile-avatar{
         height: 35px;
         width: 35px;
         margin-right: 20px;
         border-radius: 50px;
         overflow: hidden;
         display: flex;
         align-items: center;
         justify-content: center;

         img {
               height: 35px;
               width: 35px;
         }
     }
     .wm-selected-cus-img{
        margin-right: 7px !important;
     }
     .wm-relative-img{
        position: relative;
        left: 7px;
     }

  }

.wm-home-cart {
  // width: 50%;
  @include respond-to('x-small') {
    position: unset !important;
    display: flex;
    justify-content: flex-end;
  }

  @include respond-to('small') {
    position: unset !important;
    display: flex;
    justify-content: flex-end;
  }

  @include respond-to('medium') {
    flex-grow: inherit !important;
  }

  @include respond-to('large') {
    flex-grow: inherit !important;
  }

}

.navbar-light .sub .mmc_flex__item--inherit {
  &.align--center {
    &.wm-name-left {
      @include respond-to('x-small') {
        padding-left: 0 !important;
        border-right: 1px solid #b7b7b7 !important;
        border-left: none !important;
      }

      @include respond-to('small') {
        padding-left: 0 !important;
        border-right: 1px solid #b7b7b7 !important;
        border-left: none !important;
      }

      @include respond-to('medium') {
        padding-left: 0;
        border-right: none !important;
      }

      @include respond-to('large') {
        padding-left: 0;
        border-right: none !important;
      }
    }
  }
}

.wm-header-item {
  display: none;
}

.wm-top-name {
  @include respond-to('x-small') {
    display: flex;
    justify-content: flex-start !important;
  }

  @include respond-to('small') {
    display: flex;
    justify-content: flex-start !important;
  }

  @include respond-to('medium') {
    justify-content: flex-end !important;
  }

  @include respond-to('large') {
    justify-content: flex-end !important;
  }

}

.user {
  background-color: #ffffff;
  .header__alter-menu{
    .wm-top-name {
      @media (min-width: 300px) {
        display: flex;
        justify-content: flex-start !important;
      }
      @media (min-width: 768px) {
        display: flex;
        justify-content: flex-end !important;
        
      }
      @media (min-width: 1024px) {
        justify-content: flex-end !important;
      }
      @media (min-width: 1200px) {
        justify-content: flex-end !important;
      }
    }
  }
}

.wm-sub-d-flex {
  width: 100%;
}

.wm-header-upper {
  @include respond-to('x-small') {
    padding-left: 25px;
  }

  @include respond-to('small') {
    padding-left: 25px;
  }

  @include respond-to('medium') {
    position: unset;
  }

  @include respond-to('large') {
    position: unset;
  }

}

.wm-wp-mr {
  @include respond-to('x-small') {
    width: unset !important;
  }

  @include respond-to('small') {
    width: unset !important;
  }

  @include respond-to('medium') {
    width: unset;
    min-width: fit-content;
  }

  @include respond-to('large') {
    width: unset;
    min-width: fit-content;
  }

}

.wm-header-search {
  @include respond-to('x-small') {
    margin-left: unset !important;
  }

  @include respond-to('small') {
    margin-left: unset !important;
  }

  @include respond-to('medium') {
    margin-left: 20px !important;
  }

  @include respond-to('large') {
    margin-left: 20px !important;
  }
}

.wm-header-customer {
  @include respond-to('x-small') {
    padding-left: 15px;
    padding-right: 0 !important;
  }

  @include respond-to('small') {
    padding-left: 15px;
    padding-right: 0 !important;
  }

  @include respond-to('medium') {
    padding-left: 15px;
    padding-right: 0 !important;
  }

  @include respond-to('large') {
    padding-left: 15px;
    padding-right: 0 !important;
  }
}

.wm-go-back {
  @include respond-to('x-small') {
    position: absolute;
    top: -160px;
    z-index: 1000;
    left: 215px;
  }

  @include respond-to('small') {
    position: absolute;
    top: -160px;
    z-index: 1000;
    left: 215px;
  }

  @include respond-to('medium') {
    position: unset;
  }

  @include respond-to('large') {
    position: unset;
  }
}

.wm-customer {
  min-width: fit-content;
}

.wm-select-profile {
  height: auto !important;
  border: 1px solid lightgrey;
  background-color: #f2f2f2;
  border-radius: 3px;
  position: relative;
  top: -3px;
  margin: 0px 10px;
}

.wm-header-close-btn {
  font-size: 16px !important;
}
.header-menu-btn{
  padding: 0px !important;
  width: unset;
  min-width: unset;
  max-width: unset;
}
.header-menu-btn:hover{
  background: unset !important;
}
.header-menu-btn .mat-button .mat-button-focus-overlay {
  opacity: 0 !important;
}
.userprofile-wrapper{
  display: flex;
  @media screen and (max-width:400px) {
    // padding-top: 10px !important;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}
.sub {
  @media screen and (max-width:400px) {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
  }
}
.user__left{
  border-left: none !important;
  // @media screen and (max-width:768px) {
  //   border-left: none !important;
  // }
  @media screen and (max-width:400px) {
    border-left: none !important;
    padding-bottom: 10px;
    margin-top: 10px;
  }
  .user__account{
    border-left: 1px solid #b7b7b7;

    @media screen and (max-width:768px) {
        border-left: none !important;
    }
    @media screen and (max-width:400px) {
      border-left: none !important;
      padding-bottom: 10px;
      margin-top: 10px;
    }
  }

  .mat-button{
    min-width: 40px !important;
  }
}
.wm-login-user-profile-icon-wrapper{
  @media only screen and (max-width:400px) {
    width: 40px !important;
  }
}
.wm-sign-in-btn{
  @media only screen and (max-width:768px) {
    width: 12ch !important;
    a {
      padding: 0 5px !important;
    }
  }
  @media only screen and (max-width:400px) {
    width: 100% !important;
    a {
      padding: 0 5px !important;
    }
  }
}

.user__account__text{
  white-space: nowrap;
  min-width: 11ch;
}
.wm-login-user-profile-icon-wrapper.wm-selected-customer-profile {
  width: unset !important;
 }
 .wm-login-user-profile-icon-wrapper.wm-selected-customer-profile {
  width: unset !important;
  display: flex;

  .header_nav.wm-selected-customer-profile.wm-select-profile{
    span:before{
      display: none;
    }
  }
  span.wp-txt-body1--small.wp-txt--link.header_nav.wm-selected-customer-profile.wm-select-profile{
    display: flex;
  }
}
.user-menue-wrapper{
    display: flex;
    align-items: center;
}

@media print{
  .user{
    .header__alter-menu{
      .wm-top-name{
        justify-content: flex-end !important;
      }
    }
  } 
}