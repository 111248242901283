@import "../variables";

.wm-payment-modal {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.wm-payment-modal-header {
    z-index: 9999;
    background-color: #fff;
    opacity: 1;
    margin-bottom: 3px;
    border-radius: 4px 4px 0 0;
    width: 100%;
    @include box_shadow (1);
}

.wm-payment-modal-body {
    height: 550px;
    max-height: 80vh;
    padding: 0 !important;
}

iframe {
    .opt-add-note-wrapper {
        .opt_modal-body {
            .mat-tab-body-wrapper {
                section {
                    .select_invoice-section {
                        .opt_flex--container {
                            padding: 15px;
                                    .opt_flex__item {
                                        mat-checkbox {
                                            .mat-checkbox-label {
                                                padding-left: 15px;
                                                div {
                                                    padding: 3px 0;
                                                }
                                            }

                                        }

                                    }
                                    .price-value {
                                        text-align: right;
                                        align-self: center;
                                        font-weight: 700;
                                    }
                                }
                        
                    }
                    .billing-info-section {
                        .opt_flex--container {
                            .opt_flex__item {
                                padding: 10px !important;
                                flex-basis: 50% !important;
                                .mat-form-field {
                                    width: 100% !important;
                            }
                            }
                        }
                    }
                    .notes-section {
                        .opt_flex--container {
                            .opt_flex__item {
                                padding: 10px !important;
                                flex-basis: 50% !important;
                                .mat-form-field {
                                    width: 100% !important;
                                }
                            }
                        }

                    }


                }

            }

        }
        

    }
    .note-button-row {
        height: 75px;
        margin-bottom: -10px;
        width: 100%;
        /* max-width: 92vw; */
        border-radius: 0 0 4px 4px;
        position: fixed;
        bottom: 2%;
        padding: 10px;
        z-index: 9999;
        background-color: #f5f5f5;
        opacity: 1;
        @include box_shadow (1);
        // box-shadow: 0 -2px 5px 0 rgb(0 0 0 / 14%), 0 0 2px -2px rgb(0 0 0 / 20%), 0 1px 5px 1px rgb(0 0 0 / 12%);

        .opt_flex--container {
            button {
                margin: 0 10px;
            }

        }
    }

    .expand-outer-wrapper {
        padding: 30px 0;
        .expand-inner-wrapper {
            padding: 0 15px;
            background-color: #f5f5f5;
        }
        .billing-inner-wrapper {
            padding: 10px;
            border: 1px solid #e0e0e0;
        }
    }

    .setwidth {
        width: 500px!important;
    }

    section {
        mat-expansion-panel-header {
            .opt-txt__body2 {
                color: black !important;
            }
        }

    }
}

iframe {
    .opt-corporate-client {
        .client-details  {
            mat-horizontal-stepper {
                .text-center {
                    white-space: break-spaces !important;
                }
            }
        }
    }
}

.disabled-card-method {
    cursor: not-allowed !important;
    opacity: 0.4 !important;
    .material-icons {
        cursor: not-allowed !important;
        opacity: 0.4 !important;
    }
    .wm-icon-caption {
        cursor: not-allowed !important;
        opacity: 0.4 !important;
    }
}