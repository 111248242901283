@import "../variables.scss";

.wm-modals-container {
  h2 {
    @include respond-to('x-small') {
      font-size: 20px;
    }

    @include respond-to('small') {
      font-size: 20px;
    }

    @include respond-to('medium') {
      font-size: 24px;
    }

    @include respond-to('large') {
      font-size: 24px;
    }
    
  }
    h3 {
    @include respond-to('x-small') {
      font-size: 18px;
    }

    @include respond-to('small') {
      font-size: 18px;
    }

    @include respond-to('medium') {
      font-size: 20px;
    }

    @include respond-to('large') {
      font-size: 20px;
      font-weight: 600;
    }
    
  }
h4 {
    @include respond-to('x-small') {
        font-size: 16px !important;
        font-weight: 600 !important;
        padding-bottom: 20px !important;
    }

    @include respond-to('small') {
        font-size: 16px !important;
        font-weight: 600 !important;
        padding-bottom: 20px !important;
    }

    @include respond-to('medium') {
        font-size: 18px !important;
        font-weight: 600 !important;
        padding-bottom: 20px !important;
    }

    @include respond-to('large') {
        font-size: 18px !important;
        font-weight: 600 !important;
        padding-bottom: 20px !important;
    }  
}
  p {
    @include respond-to('x-small') {
      font-size: 14px !important;
    }

    @include respond-to('small') {
      font-size: 14px !important;
    }

    @include respond-to('medium') {
      font-size: 16px !important;
    }

    @include respond-to('large') {
      font-size: 16px !important;
    }
    
  }

  ul {
    @include respond-to('x-small') {
      padding: 0 5px;
    }

    @include respond-to('small') {
      padding: 0 5px;
    }

    @include respond-to('medium') {
      padding: 0 5px;
    }

    @include respond-to('large') {
      list-style-type: none;
      padding: 0 5px;
    }
    
    li {
        padding-left: 30px !important;
        a {
            color: blue !important;
        }
        div {
            position: absolute;
            margin-left: -30px;

            .green {
                color: green !important;
            }
            .red {
                color: red !important;
            }
        }
      @include respond-to('x-small') {
        font-size: 13px !important;
      }

      @include respond-to('small') {
        font-size: 13px !important;
      }

      @include respond-to('medium') {
        font-size: 15px !important;
      }

      @include respond-to('large') {
        font-size: 15px !important;
      }
      
    }
  }
}

.wm-collect-modal {
  @include respond-to('x-small') {
    padding: 20px 15px;
}

@include respond-to('small') {
    padding: 20px 15px; 
}

@include respond-to('medium') {
    padding: 20px 20px;
}

@include respond-to('large') {
    padding: 20px 20px;
}
}



.what-we-collection-modal{
  .d-flex{
    padding: 10px 0 0 0;
}
  .ml-20{
    margin-left: 20px;
}
  .wm-what-we-collect-title{
    margin: 15px 27px;
    // margin: 12px 0 15px 27px;
    font-size: 20px;
}
  .wm-what-we-collect-content{
    margin: 10px 17px;
    // margin: 10px 0 0 17px;
    overflow: hidden;

    .material-icons {
      margin-right: 5px;
  }
    .wm-icon-error{
      .material-icons{
        font-weight: bolder;
        color:$tour-color--product !important;
    }
  }
    .wm-icon-success{
      .material-icons{
        font-weight: bolder;
        color: $green-light-color !important;
    }

  }
    .wm-what-we-collect-sub-title {
      padding: 15px 0 11px 10px;
  }
    a{
      text-decoration: underline;
  }
}
  .mmc-modal-footer{
    border-top: 1px solid #989696;
    padding: 10px;
    margin-top: 0px;
    button{
      width: 100px;
      margin-right: 19px;
  }
}

}

.wm-modals-container ul li {
    padding-left: 30px !important;
    position: relative !important;
}

.wm-modals-container ul li div {
    position: absolute !important;
    margin-left: -30px !important;
}

.wm-modals-container ul li .green {
    color: #008000 !important;
    position: absolute !important;
    margin-left: -30px !important;
}

.wm-modals-container ul li .red {
    color: #e00 !important;
    position: absolute !important;
    margin-left: -30px !important;
}

.wm-modals-container a {
    color: blue !important;
}

.wm-modals-container h4 {
    font-size: 18px !important;
    font-weight: 600 !important;
    padding-bottom: 20px !important;
}
.wm_search-add-client_iframe {
  width:100%;
  height: calc(100vh - 230px) !important;
  border:none;
}
