@import "../variables";

$wm-text--color : $font-color;
$wm-border--color : $opt-border-color;


.wm {
    &-dialog-header {

		@include respond-to('x-small') {
			min-width: 0px;
		  }

		  @include respond-to('small') {
			min-width: 0px;
		  }
		
		  @include respond-to('medium') {
			min-width: 0px;
		  }
		
		  @include respond-to('large') {
			border-bottom: 1px solid $wm-border--color  !important;
			height: 60px;
			margin-top: -24px;
			margin-left: -24px;
        	margin-right: -24px;
        	min-width: 1000px;
		  }
		
		// .icon {
		// 	height: 60px;
		// 	flex: 60px 0 0;
		// 	background-color: #ffd402;
		// 	.material-icons {
		// 		font-size: 30px;
		// 		margin-left: 14px;
		// 		margin-top: 15px;
		// 	}
		// }
		.title {

			@include respond-to('x-small') {
				display: flex;
				flex-direction: column;
				align-items: end;
			  }
	
			  @include respond-to('small') {
				display: flex;
				flex-direction: column;
				align-items: end;
			  }
			
			  @include respond-to('medium') {
				flex-direction: column;
				align-items: end;
			  }
			
			  @include respond-to('large') {
				flex: 70 0 auto;
				height: 60px;
				padding-left: 20px;
				flex-direction: row;
			  }

			
			h2 {
				margin: 2px 0px 1px 0px;
				align-self: center;
			}
		}

        .status-title {

			@include respond-to('x-small') {
				align-self: end;
			  }
	
			  @include respond-to('small') {
				align-self: end;
			  }
			
			  @include respond-to('medium') {
				align-self: end;
			  }
			
			  @include respond-to('large') {
				flex: 15 0 auto;
				text-align: center;
				font-size: 16px;
				font-weight: 600;
				align-self: center;
			  }

           
        }

		.btn-cage {
			flex: 15 0 auto;
			height: 60px;
			text-align: right;
			.material-icons {
				font-size: 30px;
				margin-right: 20px;
				margin-left: 14px;
				margin-top: 15px;
				cursor: pointer;
			}

		}

	}
    
}

.wm-header-details-wrapper {
	@include respond-to('x-small') {
		margin: 0;
	  }

	  @include respond-to('small') {
		margin: 0;
	  }
	
	  @include respond-to('medium') {
		margin: 0;
	  }
	
	  @include respond-to('large') {
		display: flex;
		background-color: lightgray;
		// margin: 0px 25px;
		padding: 6px 0;
		border-radius: 5px;
	  }
	
	.material-icons {
		padding: 5px 5px
	}
	
   

	.wm-location-name {
		padding: 4px 12px;

	}

}
// .wm-card-wrapper{
//     padding: 10px 0;
//     .wm-missed-collection-card-body{
//         background-color: #e0e0e0;
//         box-shadow: none;
//         display: flex;
//         height: 60px;
//         .wm-mat-location-icon{
//             padding-top: 17px;
//             flex: 5% 0 0;

//         }
//     }
//     .wm-card-title{
//         align-self: center;
//         flex: 95% 0 0;

//     }
// }
.wm-input-collection{
    padding: 15px 0;
}


.mat-form-field {
    width: 100% !important;
}
