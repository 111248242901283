

.wm {

  &-payment-method {

    &-wrapper{
      padding: 25px;

      &-inner{

        &-title{
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 40px;
        }
        button{
              @include respond-to('x-small') {
                 width: 100%;
              }

              @include respond-to('small') {
                  width: 100%;
              }

              @include respond-to('medium') {
                width: 20% !important;
              }

              @include respond-to('large') {
                width: 20% !important;
              }

        }
      }

      &-content{
            margin-bottom: 70px;
             @include respond-to('x-small') {
                 flex-direction: column;
              }

              @include respond-to('small') {
                 flex-direction: column;
              }

              @include respond-to('medium') {
                flex-direction: row;
              }

              @include respond-to('large') {
                display: flex;
               justify-content: space-around;
              }

        .wm-border-box{
         // padding: 55px;
              border: 1px solid #595959;
              cursor: pointer;
             @include respond-to('x-small') {
                padding: 40px;
                margin-bottom: 10px;

              }

              @include respond-to('small') {
                 padding: 40px;
                 margin-bottom: 10px;
              }

              @include respond-to('medium') {
               padding: 40px 80px;
              }

              @include respond-to('large') {
               padding: 40px 80px;
               width: 380px;
              }

              .wm-icon-caption{
                   @include respond-to('x-small') {
                     width: 100%;
                   }
                   @include respond-to('small') {
                      width: 100%;
                   }

                   @include respond-to('medium') {
                      width: 100%;
                   }

                   @include respond-to('large') {
                      width: 100%;
                   }
              }
        }
        .wm-pay-card-icon-wrapper{
              text-align: center;
          .material-icons{
            font-size: 80px !important;
            cursor: pointer;
          }
          .wm-icon-caption{
             cursor: default;
          }
        }
      }
    }
  }

}
