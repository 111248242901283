@import "../variables";


$man-semi-bold: 600;



.wm {

    &-details-wrapper {

        @include respond-to('x-small') {
            flex-direction: column;
          }

          @include respond-to('small') {
            flex-direction: column;
          }

          @include respond-to('medium') {
            flex-direction: column;
          }

          @include respond-to('large') {
            display: flex;
            flex-direction: row;
          }


        .wm-waste-details-content {

            @include respond-to('x-small') {
				flex: 0 0 100%;
			  }

			  @include respond-to('small') {
				flex: 0 0 100% ;
			  }

			  @include respond-to('medium') {
				flex: 0 0 100% ;
			  }

			  @include respond-to('large') {
				flex: 0 0 70%;
			  }


            .wm-header-details-wrapper {
                @include respond-to('x-small') {
                    margin: 0;
                  }

                  @include respond-to('small') {
                    margin: 0;
                  }

                  @include respond-to('medium') {
                    margin: 0;
                  }

                  @include respond-to('large') {
                    display: flex;
                    background-color: lightgray;
                    margin: 0px 25px;
                    padding: 6px 0;
                    border-radius: 5px;
                  }

                .material-icons {
                  padding: 5px 5px !important;
                }


                .wm-location-name {
                  display: flex;
                  align-items: center;
                  padding: 4px 5px;

                }

            }

        }
        %wm-b-cart-comon-style{
          color: #f20000;
          cursor: pointer;
          font-weight: bold;
        }
        .wm-b-cart-mc-report-text {
          @extend %wm-b-cart-comon-style;
          padding-top: 10px;
          white-space: pre-line;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 0 0 100%;

        }
        .wm-b-cart-mc-sub-report-text{
          @extend %wm-b-cart-comon-style;
          display: inline-block;
          text-align: center;
          font-size: 15px;


          @include respond-to('x-small') {
            margin-left: 10px;
          }

          @include respond-to('small') {
            margin-left: 10px;
          }

        }


        .wm-detail-item-wrapper {

          @include respond-to('x-small') {
            margin: 10px 0px;
          }

          @include respond-to('small') {
            margin: 10px 0px;
          }

          @include respond-to('medium') {
            margin: 10px 0px;
          }

          @include respond-to('large') {
            margin: 10px 25px;
          }


            .expand-card-view{
              @include box_shadow (1);
              background-color: white;
            }
            .wmb-cart-box-shadow{
              @include box_shadow (1);
            }

            .wm-detail-item-box {

                @include respond-to('x-small') {
                    flex-direction: column;
                    padding: 25px 20px;
                  }

                  @include respond-to('small') {
                    flex-direction: column;
                    padding: 25px 20px;
                  }

                  @include respond-to('medium') {
                    flex-direction: column;
                  }

                  @include respond-to('large') {
                    display: flex;
                    flex-direction: row;
                    @include box_shadow (1);
                    padding: 12px 20px;
                    background-color: white;
                    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                    border-radius: 5px;
                    flex-wrap: wrap;
                  }

                  &:hover {
                    box-shadow: 0px 0px 5px 3px #bdbdbd;
                  }



                .wm-deatils-icon-wrapper {

                    @include respond-to('x-small') {
                        flex: 0 0 100%;
                      }

                      @include respond-to('small') {
                        flex: 0 0 100%;
                      }

                      @include respond-to('medium') {
                        flex: 0 0 100%;
                      }

                      @include respond-to('large') {
                        flex: 0 0 20%;
                      }





                    .wm-deteail-image {


                        .wm-details-img {

                          @include respond-to('x-small') {
                            width: 100%;
                            height: 150px;
                          }

                          @include respond-to('small') {
                            width: 100%;
                            height: 150px;
                          }

                          @include respond-to('medium') {
                            width: 100%;
                            height: 55vh;
                          }

                          @include respond-to('large') {
                            width: 100%;
                            height: 110px;
                            object-fit: contain;
                          }

                        }
                    }

                }

                .wm-detail-name-wrapper {

                    @include respond-to('x-small') {
                        flex: 0 0 100%;
                      }

                      @include respond-to('small') {
                        flex: 0 0 100%;
                      }

                      @include respond-to('medium') {
                        flex: 0 0 100%;
                      }

                      @include respond-to('large') {
                        flex: 0 0 30%;
                      }


                    .wm-detail-name {

                      @include respond-to('x-small') {
                        font-size: 18px;
                      }

                      @include respond-to('small') {
                        font-size: 18px;
                      }

                      @include respond-to('medium') {
                        font-size: 24px;
                      }

                      @include respond-to('large') {
                        font-size: 18px;
                        font-weight: 500;
                      }
                        


                        .wm-detail-name-text {
                          color: black !important;
                          text-decoration: underline !important;
                        }

                    }

                    .wm-detail-type {

                      @include respond-to('x-small') {
                        font-size: 12px;
                      }

                      @include respond-to('small') {
                        font-size: 12px;
                      }

                      @include respond-to('medium') {
                        font-size: 18px;
                      }

                      @include respond-to('large') {
                        font-size: 12px;
                        color: gray;
                        position: relative;
                        top: -5px;
                      }
                        

                    }

                    .wm-detail-front-name {

                      @include respond-to('x-small') {
                        font-size: 16px;
                      }

                      @include respond-to('small') {
                        font-size: 16px;
                      }

                      @include respond-to('medium') {
                        font-size: 22px;
                      }

                      @include respond-to('large') {
                        color: black;
                        font-weight: 500;
                        font-size: 16px;
                        padding-top: 12px;
                      }
                        

                    }

                    .wm-detail-type-low {

                      @include respond-to('x-small') {
                        font-size: 12px;
                      }

                      @include respond-to('small') {
                        font-size: 12px;
                      }

                      @include respond-to('medium') {
                        font-size: 18px;
                      }

                      @include respond-to('large') {
                        font-size: 12px;
                        color: gray;
                        position: relative;
                        top: -5px;
                      }
                        

                    }

                }

                .wm-detail-date-wrapper {

                    @include respond-to('x-small') {
                        flex: 0 0 100%;
                      }

                      @include respond-to('small') {
                        flex: 0 0 100%;
                      }

                      @include respond-to('medium') {
                        flex: 0 0 30%;
                      }

                      @include respond-to('large') {
                        flex: 0 0 30%;
                      }


                    .wm-detail-date {

                      @include respond-to('x-small') {
                        font-size: 18px;
                      }

                      @include respond-to('small') {
                        font-size: 18px;
                      }

                      @include respond-to('medium') {
                        font-size: 22px;
                      }

                      @include respond-to('large') {
                        color: black;
                        font-weight: 500;
                        font-size: 18px;
                      }
                    }

                    .wm-detail-date-des {

                      @include respond-to('x-small') {
                        font-size: 12px;
                      }

                      @include respond-to('small') {
                        font-size: 12px;
                      }

                      @include respond-to('medium') {
                        font-size: 18px;
                      }

                      @include respond-to('large') {
                        font-size: 12px;
                        color: gray;
                        position: relative;
                        top: -4px;

                      }
                        

                    }

                    .wm-detail-date-name {

                      @include respond-to('x-small') {
                        padding-top: 12px;
                        font-size: 16px;
                      }

                      @include respond-to('small') {
                        padding-top: 12px;
                        font-size: 16px;
                      }

                      @include respond-to('medium') {
                        padding-top: 12px;
                        font-size: 20px;
                      }

                      @include respond-to('large') {
                        padding-top: 28px;
                        font-weight: 500;
                        font-size: 16px;
                        color: black;
                        margin-bottom: 6px;

                      }



                        
                    }

                }

                .wm-detail-status-item-wrapper {

                  @include respond-to('x-small') {
                    flex: 0 0 100%;
                    text-align: unset;
                  }

                  @include respond-to('small') {
                    flex: 0 0 100%;
                    text-align: unset;
                  }

                  @include respond-to('medium') {
                    flex: 0 0 20%;
                  }

                  @include respond-to('large') {
                    flex: 0 0 20%;
                    text-align: end;
                  }

                  .wm-detail-status-wrapper {

                    .wm-dettail-free {

                      @include respond-to('x-small') {
                        font-size: 18px;
                      }
    
                      @include respond-to('small') {
                        font-size: 18px;
                      }
    
                      @include respond-to('medium') {
                        font-size: 20px;
                      }
    
                      @include respond-to('large') {
                        color: black;
                        font-weight: 500;
                        font-size: 18px;
                      }
                      

                  }

                  .wm-detail-sche {
                    @include respond-to('x-small') {
                      padding-bottom: 10px;
                    }
  
                    @include respond-to('small') {
                      padding-bottom: 10px;
                    }
  
                    @include respond-to('medium') {
                      font-size: 14px;
                    }
  
                    @include respond-to('large') {
                      padding-bottom: 48px;
                    }
                     

                  }



                  }

                  .wm-detail-icons {

                    @include respond-to('x-small') {
                      justify-content: end;
                    }
  
                    @include respond-to('small') {
                      justify-content: end;
                    }
  
                    @include respond-to('medium') {
                      justify-content: end;
                    }
  
                    @include respond-to('large') {
                      display: flex;
                      justify-content: flex-end;
                      margin-bottom: 5px;

                    }

                    
                    .wm-details-edit {

                      @include respond-to('x-small') {
                        margin-right: 10px;
                        font-size: 20px;
                      }
    
                      @include respond-to('small') {
                        margin-right: 10px;
                        font-size: 20px;
                      }
    
                      @include respond-to('medium') {
                        margin-right: 10px;
                        font-size: 40px;
                      }
    
                      @include respond-to('large') {
                        margin-right: 10px;
                        font-size: 18px;
  
                      }
                      

                      .wm-icons {

                      }

                    }

                  }

                }
            }

            .wm-add-collection-btn {
              padding: 20px 0;

              .wm_add-btn {
                padding: 5px 20px;
                background-color: #d2b996 !important;
                color: #000000 !important;
                border: 1px solid #d2b996 !important;

              }
            }

            .wm-pay-info-wrapper {

              .wm-pay-name {

              }

              .wm-pay-info {
                color: black;
                font-weight: 600;

              }
            }



        }

        .wm-waste-details-card {

            @include respond-to('x-small') {
				        flex: 0 0 100% ;
			      }

            @include respond-to('small') {
                flex: 0 0 100% ;
            }

            @include respond-to('medium') {
                flex: 0 0 100% ;
            }

            @include respond-to('large') {
                flex: 0 0 30%;
                @include box_shadow (1);
                background-color: white;
                border-radius: 5px;
            }

            &:hover {
              box-shadow: 0px 0px 5px 3px #bdbdbd;
            }


           .wm-detail-card-box-wrapper {
              display: flex;
              flex-direction: column;
              padding: 15px 0px;

              .wm-card-header-name {
                font-weight: 500;
                font-size: 18px;
                color: black !important;

              }

              .wm-card-header-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                // padding-top: 15px;
                border-bottom: 1px solid lightgray;
                padding: 15px 15px;

                .wm-audit-history {
                  position: absolute;
                  right: 55px;
                  .material-icons {
                    font-size: 25px;
                    cursor: pointer;
                  }
                }

                .wm-card-des-free {
                  color: green;
                  font-weight: 600;
                }

              }

              .wm-booking {
                background-color: black;
                padding: 15px !important;
              }

              .wm-card-content-wrapper {

                @include respond-to('x-small') {
                    margin: 2px 15px !important;
              }
  
              @include respond-to('small') {
                  margin: 2px 15px !important;
              }
  
              @include respond-to('medium') {
                  margin: 10px 15px !important;
              }
  
              @include respond-to('large') {
                  // @include box_shadow (2);
                  padding: 10px 15px;
                  margin: 2px 15px !important;
                  background-color: #f6f6f6;
                  border-bottom: 1px solid #dcdcdc;
              }
                

                .wm-card-content-name {
                  font-size: 18px;
                  font-weight: 500;

                  .wm-detail-name-text {
                    color: black !important;
                  }

                }

                .wm-card-content-date {
                  font-size: 13px;

                }

                .wm-card-content-des {

                }

                .wm-card-lower-wrapper {
                  display: flex;
                  justify-content: space-between;
                  padding-top: 15px;

                  .wm-card-content-free {
                    font-weight: 600;
                    font-size: 16px;

                  }

                  .wm-detail-icons {
                    display: flex;

                    .wm-details-edit {
                      margin-right: 10px;
                    }

                  }

                }

              }

              .wm-card-checkbox {
                padding: 15px 15px;

                .mat-checkbox-layout {
                  white-space: normal !important;

                  .mat-checkbox-inner-container {
                    margin-top: 5px;

                  }

                  .mat-checkbox-label {
                    font-size: 15px !important;
                  }
                }
              }

              .wm-card-btn-wrapper {
                display: flex;
                justify-content: space-between;
                margin: 0 15px;
                flex-direction: column;

                .wm-card-btn {
                  flex: 1 0;
                  padding: 0 5px;
                  margin-bottom: 15px;


                  .wm-card-button {

                    @include respond-to('x-small') {
                      width: 100%
                    }

                    @include respond-to('small') {
                      width: 100%
                    }

                    @include respond-to('medium') {
                      width: 100%
                    }

                    @include respond-to('large') {
                      width: 48% !important;
                      display: flex;
                      align-items: center;
                      border-radius: 25px !important;
                      @include box_shadow (1);
                    }

                  }

                  .wm-card-update-button {
                    @include respond-to('x-small') {
                      width: 100%
                    }

                    @include respond-to('small') {
                      width: 100%
                    }

                    @include respond-to('medium') {
                      width: 100%
                    }

                    @include respond-to('large') {
                      width: 100% !important;
                      display: flex;
                      align-items: center;
                      border-radius: 25px !important;
                      height: 50px !important;
                    }

                  }
                }
              }

              .wm-card-cancel-btn {
                padding: 15px 0;
                display: flex;
                justify-content: center;

                .wm-cancel-btn {
                  flex: 1 0;
                  padding: 0 20px;

                  .wm-card-cancel-button {

                    @include respond-to('x-small') {
                      width: 140px;
                    }

                    @include respond-to('small') {
                      width: 140px;
                    }

                    @include respond-to('medium') {
                      width: 175px;
                    }

                    @include respond-to('large') {
                      display: flex;
                      align-items: center;
                      border-radius: 25px !important;
                      width: 48% !important;
                      position: relative;
                      top: -66px;
                      float: right;
                      @include box_shadow (1);
                    }

                  }

                  .wm-card-close-button {
                    @include respond-to('x-small') {
                      width: 140px;
                    }

                    @include respond-to('small') {
                      width: 140px;
                    }

                    @include respond-to('medium') {
                      width: 175px;
                    }

                    @include respond-to('large') {
                      display: flex;
                      align-items: center;
                      border-radius: 25px !important;
                      width: 100% !important;
                    }
                  }

                  .wm-refund-button {
                    padding: 1;
                  }
                }
              }




           }

        }

    }

}

.wm-detail-p-name {
  @include respond-to('x-small') {
    padding: 0 0px;
  }

  @include respond-to('small') {
    padding: 0 0px;
  }

  @include respond-to('medium') {
    padding: 0 10px;
  }

  @include respond-to('large') {
    padding: 0 10px;
  }

}
