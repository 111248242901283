.wm-payment-wrapper{
  padding: 30px 30px 0px 30px;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0 , 0 , 0 ,0.12), 0 , 1px , 2px rgba(0 , 0 , 0 ,0.24);
  margin-bottom: 40px;
  margin-top: 40px;

   .wm-payment-body{
     .wm-payment-header-content{
        max-width: 880px;
        margin: auto;
        border-radius: 5px;
        @media only screen and (max-width: 375px) {
          padding-left: 24px;
        }
     }
   }

   .wm_payment-header {
    font-size: 25px;
    color: #66615b;
    max-width: 880px;
    margin: auto;
    padding-bottom: 35px;
   }

  .wm-pay-title{
    font-size: 14px;
    color: black;
    font-weight: 600;
    text-align: end;
    @media only screen and (max-width: 376px) {
      margin-left: 0px !important;
    }
  }
  .wm-payment-spinner-wrapper{
    height: 250px;
    text-align: center;
  }
  .with-height{
    //min-height: 350px;
     min-height: 75vh;
     width: 100%;
     border: none;
     max-width: 925px;
     display: flex;
     margin: auto;
  }
  .without-height{
    min-height: 0px;
  }

}
