@import "../variables";

$wm-text--color : $font-color;
$wm-border--color : $opt-border-color;


.wm-dialog-header{
    @include respond-to('x-small') {
        min-width: 0px;
                         
    }
    @include respond-to('small') {
        min-width: 0px;
      
    }
  
    @include respond-to('medium') {
        min-width: 0px;
                             
    }
  
    @include respond-to('large') { 
        min-width: 1000px;  
                          
            
    }
    .mmc_flex--container{
        .mmc_flex__item{
            
    @include respond-to('x-small') {

                         
    }
    @include respond-to('small') {
      
    }
  
    @include respond-to('medium') {
                             
    }
  
    @include respond-to('large') {                     
            
    }

        }
    }
    .material-icons{

    }


}



        // display: flex;
        .wm-item-rating{

            @include respond-to('x-small') {
                border-bottom: 1px solid #d2b996;

                         
            }
            @include respond-to('small') {
                border-bottom: 1px solid #d2b996;
              
            }
          
            @include respond-to('medium') {
                                     
            }
          
            @include respond-to('large') { 
                display: flex;
                padding: 10px 0px;                    
                    
            }
            
            .wm-rating{
                @include respond-to('x-small') {
                    padding-right: 10px;
    
                             
                }
                @include respond-to('small') {
                    padding-right: 10px;
                  
                }
              
                @include respond-to('medium') {
                    flex: 50% 0 0; 
                                         
                }
              
                @include respond-to('large') { 
                    flex: 50% 0 0;                   
                        
                }
                 
            }
            .we-icon{
                .wm-rating-poor{
                    font-size: 12px;
                    color: red;  
                }
            }
            .wm-rating-name{
                font-weight: 500;
            }
        }
        .wm-item-rating-last{
            padding-top: 10px;
            font-size: 12px;
        }

// .wm-modal-header {
//     height: 85px !important;
// }