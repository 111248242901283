

.waste-collection-image-upload-wrapper{

   .wm-upload-img-btn{
     border-radius: 30px;
     border: 1px solid #d2b996;
     background: #d2b996;
     font-size: 14px;
     padding: 4px 10px 8px 14px;
     cursor: pointer;

     .material-icons{
       font-size: 20px;
       color: #545050 !important;
       position: relative;
       top: 5px;
     }
   }
  .waste-collection-image-upload-content{
    border-radius: 10px;

    .drop-zone{
      border: none !important;
      background-color:unset !important;
      box-shadow: none !important;

      .material-icons{
        color: #eae5e5 !important;
      }
    }

  }
}

.uploaded-img-text-wrapper{
  margin-bottom: 8px;

  .wm-img-tag{
    padding: 8px 0 5px 13px;
    border-radius: 25px;
    background: #ededed;
    text-transform: capitalize;
    font-size: 13px;
    max-width: 300px;
    width: 300px;
    color: #95989a;
  }
  .material-icons{
    padding: 6px;
    background: #bbbbbb;
    border-radius: 30px;
    color: #ffffff !important;
    margin-left: 12px;
    margin-top: 0px;
  }
  .wm-icon-weapper{
    cursor: pointer;

  }
}

