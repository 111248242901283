
    .wm-collection-spinner-wrapper , .wm-collection-status-spinner{
      display: flex;
      flex-direction: column;
      align-items: center;

      .font__small{
        margin-top:35px;
      }
    }
    .wm-collection-status-spinner{
      text-align: center;
      margin-top: 50px;

      .internal-server-error-spinner{
        .material-icons{
          font-size: 90px !important;
          color: #e64040 !important;
        }
      }
      .internal-server-error-text{
        color:red;
      }
    }
