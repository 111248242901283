
.wm-highlight-dates{
  background: $wm-highlight-date-bg ;
  border-radius: 100% !important;
}

.wm-date-disabled{
  pointer-events: none;
  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected){
    color: rgba(0,0,0,.38) !important;
  }
}
.wm-no-date-availability{
  pointer-events: none;

  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected){
    color: rgba(255, 255, 255, 0.38) !important;
    background: #ff384a8c;
  }
}
.wm-highlight-disabled-dates{
  background:$wm-disable-date-bg;
  border-radius: 100% !important;
  pointer-events: none !important;
}


.wm-btn-disabled{
  cursor: not-allowed;
}

.wm-btn-spinner-cage{
  text-align: center !important;
  text-align: -webkit-center !important;
}
.wm-modal-btn:disabled{
  border: 1px solid $wm-button-disabled-border !important;
  background: $wm-button-disabled-bg !important;
}

.wm-single-pkg-label-wrapper{

  .mat-form-field-underline{
     display: none  !important;
  }

  .wm-pkg-label-disabled {
    display: flex;
    background-color: $wm-label-disabled;
    padding: 8px 8px;
    border-radius: 5px;
    color: #635e5e;
  }

}

.lh-0{
  line-height: 0px !important;
}

.wm-btn__cage:disabled{
   cursor: not-allowed  !important;
}

.wm-cancelled-booking-txt-color {
  .wm-color-code1{
     color: $wm-cancel-booking-color !important;
   }

  .message-icon{
    border: 3px solid $wm-cancel-booking-color !important;

    .material-icons{
      color:$wm-cancel-booking-color !important;
    }

  }

}

.additional-info-wrapper{
  margin-top: 15px;
  .additional-info-title{
    flex-direction: column;
    width: 50%;

    .question{
      font-size: 13px;
    }
  }
  .wm-additional-det-radio-group{
    display: flex;
    flex-direction: column;

    .label{
      position: relative;
      top: 4px;
    }

}
}

.wm-client-search-popup{
  max-width: 90vw !important;

  .mat-dialog-container{
     overflow-y: unset  !important;
     overflow: unset;
  }
    ::-webkit-scrollbar {
      width: 10px !important;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
      border-radius: 10px !important;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      -webkit-box-shadow: inset 0 0 6px #565353  !important;
      background-color: $wm-scrollbar-color  !important;
    }

}

//-- Fully booked ToolTip -------------- //
.wm-calendar {
  .wm-fully-booked-dates, .wm-unavailable-dates {
    background:$wm-disable-date-bg;
    border-radius: 100% !important;
    //pointer-events: none; 

    .mat-calendar-body-cell-content {
      color: #a19d9d;
    }
    .wm-tooltip{
      // cursor: not-allowed;

      .tooltipText {
        visibility: hidden;
        width: 120px;
        background-color: $wm-date-toolTip-bg;
        color: $wm-date-color;
        text-align: center;
        border-radius: 6px;
        padding: 8px 0;
        position: absolute;
        z-index: 1;
        bottom: 120%;
        margin-left: -60px;
        left: 50%;


      }
      &:hover {
        pointer-events: none;

        &:after {
          content: "";
          position: absolute;
          top: -25%;
          left: 50%;
          margin-left: -8px;
          border-width: 8px;
          border-style: solid;
          border-color: $wm-date-toolTip-bg transparent transparent transparent;
        }

        .tooltipText {
          visibility: visible;
        }
      }
    }
  }

}

.mat-dialog-container {
  height: auto !important;
}

