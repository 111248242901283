.wm-confirm-header {
    @include respond-to('x-small') {
        font-size: 21px !important;
      }

      @include respond-to('small') {
        font-size: 21px !important;
      }

      @include respond-to('medium') {
        font-size: 25px !important;
      }

      @include respond-to('large') {
        font-size: 26px !important;
      }
}

.wm-message-icon {
    width: 35px !important;
    height: 35px !important;

    .material-icons {
      color: #2E7D32 !important;
    }
}

.wm-body-confirm {
    @include respond-to('x-small') {
        flex-direction: column !important;

      }
      @include respond-to('small') {
        flex-direction: column !important;

      }

      @include respond-to('medium') {
        flex-direction: row !important;
      }

      @include respond-to('large') {
        flex-direction: row !important;
      }
}

.wm-confirm-header-title {
    @include respond-to('x-small') {
      font-size: 22px;

      }
      @include respond-to('small') {
        font-size: 22px;

      }

      @include respond-to('medium') {
        font-size: 24px;
      }

      @include respond-to('large') {
        font-size: 24px;
      }

}

.wm-header-wrapper {
    @include respond-to('x-small') {
        padding-bottom: 10px;

      }
      @include respond-to('small') {
        padding-bottom: 10px;

      }

      @include respond-to('medium') {
        padding-bottom: 10px;
      }

      @include respond-to('large') {
        padding-bottom: 10px;
      }

}

.wm-confirm-date-wrapper {
    @include respond-to('x-small') {
        justify-content: flex-start !important;

      }
      @include respond-to('small') {
        justify-content: flex-start !important;

      }

      @include respond-to('medium') {
        justify-content: flex-end;
      }

      @include respond-to('large') {
        justify-content: flex-end;
      }

}

.wm-confirm-header-wrapper {
  @include respond-to('x-small') {
    padding: 12px 5px;

  }
  @include respond-to('small') {
    padding: 12px 5px;

  }

  @include respond-to('medium') {
    padding: 15px 5px;
  }

  @include respond-to('large') {
    padding: 15px 5px;
    border-bottom: black solid 1px;
  }

}
.wm-cancel-remove-booking-info-text{
  position: relative;
  top :-60px;
  margin: 0 15px;

  p {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
}

.wm-bd-cb-direction-url {
  color: #0b0baa !important;
  text-decoration: underline !important;
}
.wm-clear-cart-btn{
  margin-top: 10px !important;
}
.mt-b-15 {
  margin-bottom: 15px;
}
