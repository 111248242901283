@import "../variables";

.wm {
  &-border-right {
    border-right: 1px solid $wm-border--color;
  }

  &-two-coll {
    color: $wm-text--color;

    .mmc_flex--container {
      // height: 500px;

      @include respond-to("x-small") {
        flex-direction: column;
      }
      @include respond-to("small") {
        flex-direction: column;
      }

      @include respond-to("medium") {
        flex-direction: column;
      }

      @include respond-to("large") {
        flex: 50% 0 0;
        flex-direction: row;
      }
    }

    .wm_header {
      font-size: 25px;
    }
    .wm-sub_header {
      font-size: 15px;
      padding: 15px 10px;
      // margin-top: 15px;
    }

    // .wm-search-cage{
    //         text-align: center;
    //         margin-top: 18px;
    //         margin: 30px 10px;

    //         .wm-booking-search{
    //           width: 80% !important;

    //           .mat-form-field-wrapper {
    //             margin: 0 !important;
    //             padding: 0 !important;
    //           }

    //       }

    // }

    .wm-btn--cage {
      text-align: center;
      margin-top: 35px;
      .wm-btn__cage {
        width: 195px;
        &:focus {
          outline: 2px solid black;
        }
      }
    }

    .wm-tab-list {
      padding-top: 15px;

      .mat-tab-body {
        background-color: #f5f5f5 !important;
        padding-top: 0;
      }
    }

    .wm-status-card-list {
      margin: 15px 5px;
    }

    .wm-status-card {
      @include respond-to("x-small") {
        flex-direction: column;
      }
      @include respond-to("small") {
        flex-direction: column;
      }

      @include respond-to("medium") {
        flex-direction: row;
      }

      @include respond-to("large") {
        display: flex;
        flex-wrap: wrap;
        // box-shadow: 0px 0px 10px 0px rgb(223 223 223);
        padding: 10px 10px;
        margin: 8px 0;
        border: 1px solid #eee;
        border-radius: 4px;
        @include box_shadow(1);
        background-color: white;
      }
    }

    .wm-card-item-wrapper {
      @include respond-to("x-small") {
        flex: 100% 0 0;
        order: 1 !important;
      }

      @include respond-to("small") {
        flex: 100% 0 0;
        order: 1 !important;
      }

      @include respond-to("medium") {
        flex: 70% 0 0;
        order: 1 !important;
      }

      @include respond-to("large") {
        display: flex;
        flex-direction: column;
        flex: 70% 0 0;
        order: 1 !important;
      }
    }

    .wm-card-location-wrapper {
      display: flex;

      .wm-card-image {
        // .wm-location-img {
        //     width: 19px;

        // }
      }

      .wm-card-address {
        @include respond-to("x-small") {
          padding: 0px 5px;
          font-size: 14px;
        }
        @include respond-to("small") {
          padding: 0px 5px;
          font-size: 14px;
        }

        @include respond-to("medium") {
          padding: 0px 10px;
          font-size: 22px;
        }

        @include respond-to("large") {
          padding: 0px 10px;
          font-size: 16px;
          font-weight: 600;
          color: black;
        }
      }
    }

    .wm-card-description {
      @include respond-to("x-small") {
        font-size: 13px;
      }
      @include respond-to("small") {
        font-size: 13px;
      }

      @include respond-to("medium") {
        font-size: 16px;
      }

      @include respond-to("large") {
        font-size: 13px;
      }

      // padding-top: 2px;

      .wm-card-status-message {
        color: green;
        font-weight: 600;
      }
    }

    .wm-card-content {
      @include respond-to("x-small") {
        // padding-top: 2px;
        font-size: 10px;
      }
      @include respond-to("small") {
        // padding-top: 2px;
        font-size: 10px;
      }

      @include respond-to("medium") {
        // padding-top: 2px;
        font-size: 16px;
      }

      @include respond-to("large") {
        // padding-top: 2px;
        font-size: 11px;
      }
    }

    .wm-card-btn-cage-wrapper {
      @include respond-to("x-small") {
        flex: 100% 0 0;
        text-align: center;
        order: 3 !important;
      }

      @include respond-to("small") {
        flex: 100% 0 0;
        text-align: center;
        order: 3 !important;
      }

      @include respond-to("medium") {
        flex: 30% 0 0;
        text-align: end;
        order: 2 !important;
      }

      @include respond-to("large") {
        flex: 30% 0 0;
        text-align: end;
        order: 2 !important;
      }

      .wm-card-btn-cage {
        @include respond-to("x-small") {
          justify-content: center;
        }

        @include respond-to("small") {
          justify-content: center;
        }

        @include respond-to("medium") {
          justify-content: flex-end;
        }

        @include respond-to("large") {
          display: flex;
          justify-content: flex-end;
        }

        .wm-btn-cage {
          @include respond-to("x-small") {
            width: 120px;
          }

          @include respond-to("small") {
            width: 120px;
          }

          @include respond-to("medium") {
            width: 165px;
          }

          @include respond-to("large") {
            display: flex;
            align-items: center;
            width: 120px;
          }
        }
      }

      .wm-card-hyper-link {
        padding-top: 6px;
        font-size: 13px;

        .wm-collect-link {
          color: $confirmed-multiple !important;
        }
      }
    }

    // .wm-card-btn-cage {

    //     @include respond-to('x-small') {

    //       }

    //       @include respond-to('small') {

    //       }

    //       @include respond-to('medium') {

    //       }

    //       @include respond-to('large') {

    //         display: flex;
    //         justify-content: flex-end;
    //       }

    //     .wm-btn-cage {
    //         width: 120px;
    //         display: flex;
    //         align-items: center;

    //     }

    // }

    // .wm-card-hyper-link {
    //     padding-top: 6px;
    //     font-size: 13px;

    //     .wm-collect-link {
    //       color: $confirmed-multiple !important;
    //     }

    // }

    .wm-card-star-wrapper {
      @include respond-to("x-small") {
        justify-content: center;
      }

      @include respond-to("small") {
        justify-content: center;
      }

      @include respond-to("medium") {
        justify-content: flex-end;
      }

      @include respond-to("large") {
        display: flex;
        justify-content: flex-end;
      }

      .wm-card-star {
        cursor: pointer;

        .material-icons {
          font-size: 21px;
        }
      }
    }
  }
}
.wm-waste-collection-wrapper {
  .wm-filter-btn {
    border: none;
    font-size: 14px;
    cursor: pointer;
    padding: 0px 15px;
    color: #5c5c5c !important;
    position: absolute;
    top: 60px;
    z-index: 999;
    &:hover {
      text-decoration: underline;
    }
    mat-icon {
      font-size: 14px;
      height: 14px;
      color: #5c5c5c !important;
    }
  }
}
.wm-sort {
  width: 100%;
  display: flex;
  background: #efefef7a;
  border-radius: 5px;
  margin-top: 10px;
  .wm-sortfilters {
    width: 20%;
    transform: translateY(6px);
    @media (max-width: 991.98px){
      width: 25%;
    }
    .mat-form-field {
      width: 50% !important;
      padding: 0px 5px 0px 0px;
      &.wm-sorting{
        @media (max-width: 991.98px){
          padding-left: 0px !important;
        }
      }
      
      .mat-form-field-flex {
        height: 55px;
      }
    }
    .mat-select {
      .mat-select-value-text {
        display: none;
      }
      .mat-form-field-label {
        mat-label {
          display: none;
        }
      }
    }
    .mat-form-field-type-mat-select .mat-form-field-label {
      font-size: 0;
    }
    .wm-status {
      .mat-form-field-label-wrapper {
        &::before {
          font-family: "Material Icons";
          content: "\ef4f";
          font-size: 16px;
          position: absolute;
          margin-top: -5px;
        }
      }
    }
    .wm-sorting {
      .mat-form-field-label-wrapper {
        &::before {
          font-family: "Material Icons";
          content: "\e8d5";
          font-size: 20px;
          position: absolute;
          margin-top: -5px;
        }
      }
    }
    .mat-form-field-appearance-fill{
      .mat-select-arrow-wrapper{
        @media (max-width: 767.98px){
          display: none !important;
        }
      }
    }
  }

  .wm-sortBtn {
    width: 9%;
    border: none;
    height: 56px;
    margin: 5px 0 0 5px;
    // margin-right: 10px;
    border-bottom: 1px solid #7e7e7e;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    .mat-icon {
      padding-top: 15px;
      color: #0000008a !important;
    }
    @media (max-width: 767.98px){
      width: 15%;
      margin: 5px 0 0 0px;
    }
  }

  .wm-sortBtn-rotate-flip {
    border: none;
    rotate: -180deg;
    transform: translateY(-100%);
  }

  .wm-sort-search {
    width: 70%;
    @media (max-width: 767.98px){
      width: 65%;
    }
    mat-form-field {
      padding: 0px 15px 0px 15px;
      .mat-form-field-wrapper {
        padding: 0 !important;
      }
    }
  }
}

.wm-card-lower-text-wrapper {
  @include respond-to("x-small") {
    flex: 100% 0 0;
    order: 2 !important;
  }

  @include respond-to("small") {
    flex: 100% 0 0;
    order: 2 !important;
  }

  @include respond-to("medium") {
    flex: 100% 0 0;
    order: 3 !important;
  }

  @include respond-to("large") {
    flex: 100% 0 0;
    order: 3 !important;
    padding-top: 3px;
  }
}

.wm-card-status-message-header {
  font-weight: 600;
}

.mat-button-wrapper {
  font-weight: 500 !important;
}

.wm-collection-description-wrapper {
  background-color: white;
  padding: 12px 15px;
  font-size: 13px;
  margin-bottom: 35px;
  font-weight: 600;
  border: 2px solid #d2b996;
  border-radius: 7px;
}
