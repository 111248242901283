@import "../variables";

$wm-text--color : $font-color;
$wm-border--color : $opt-border-color;


.wm {
    &-dialog-header {

		@include respond-to('x-small') {
			min-width: 0px;
		  }

		  @include respond-to('small') {
			min-width: 0px;
		  }

		  @include respond-to('medium') {
			min-width: 0px;
		  }

		  @include respond-to('large') {
			border-bottom: 1px solid $wm-border--color  !important;
			height: 60px;
			margin-top: -24px;
			margin-left: -24px;
        	margin-right: -24px;
        	min-width: 1000px;
		  }

		.icon {
			height: 60px;
			flex: 60px 0 0;
			background-color: #ffd402;
			.material-icons {
				font-size: 30px;
				margin-left: 14px;
				margin-top: 15px;
			}
		}
		.title {

			@include respond-to('x-small') {
				display: flex;
				flex-direction: column;
				align-items: end;
			  }

			  @include respond-to('small') {
				display: flex;
				flex-direction: column;
				align-items: end;
			  }

			  @include respond-to('medium') {
				flex-direction: column;
				align-items: end;
			  }

			  @include respond-to('large') {
				flex: 70 0 auto;
				height: 60px;
				padding-left: 20px;
				flex-direction: row;
			  }


			h2 {
				margin: 2px 0px 1px 0px;
				align-self: center;
			}
		}
        .status-title {

			@include respond-to('x-small') {
				align-self: end;
			  }

			  @include respond-to('small') {
				align-self: end;
			  }

			  @include respond-to('medium') {
				align-self: end;
			  }

			  @include respond-to('large') {
				flex: 15 0 auto;
				text-align: center;
				font-size: 16px;
				font-weight: 600;
				align-self: center;
			  }


        }

		.btn-cage {
			flex: 15 0 auto;
			height: 60px;
			text-align: right;
			.material-icons {
				font-size: 30px;
				margin-right: 20px;
				margin-left: 14px;
				margin-top: 15px;
				cursor: pointer;
			}

		}

	}

    &-star-collection-wrapper {
        display: flex;
        flex-direction: column;
        // align-items: center;

        .wm-star-collection {
            display: flex;
			justify-content: center;

            .wm-star-rate {
                margin-right: 15px;

              .material-icons {

                  @include respond-to('x-small') {
                    font-size: 25px;
                    }

                    @include respond-to('small') {
                    font-size: 25px;;
                    }

                    @include respond-to('medium') {
                    font-size: 55px;
                    }

                    @include respond-to('large') {
                    font-size: 55px;
                    }

                }

            }
            .fa-star{
				@include respond-to('x-small') {
                    font-size: 20px;
					padding: 10px 15px;
                }
                @include respond-to('small') {
					font-size: 20px;
					padding: 10px 15px;
                }
                @include respond-to('medium') {
					font-size: 30px;
					padding: 10px 25px;
                }
                @include respond-to('large') {
					-webkit-text-stroke-color: #080707 !important;
					-webkit-text-stroke-width: 2px;
					color: white;
					font-size: 40px;
					padding: 10px 21px;
                }
            }
            .wm-star-selected{
              .fa-star{
                color: black !important;
              }
            }
        }

		.wm-star-rate-wrapper {
			@include respond-to('x-small') {
				justify-content: space-between;
				padding: 0 12px;
			}

			@include respond-to('small') {
				justify-content: space-between;
				padding: 0 12px;
			}

			@include respond-to('medium') {
				justify-content: space-evenly;
				padding: 0 0px;
			}

			@include respond-to('large') {
				display: flex;
    			justify-content: space-evenly;
    			padding: 0 45px;
			}
			

			.wm-star-rate-status {
				color: $provisional;
				font-weight: 600;

			}

			.wm-star-rate-status-good {
				color: green;
    			font-weight: 600;
			}

		}
    }

	.wm-rate-textarea {
		@include respond-to('x-small') {
			padding-top: 20px;
		}

		@include respond-to('small') {
			padding-top: 20px;
		}

		@include respond-to('medium') {
			padding-top: 25px;
		}

		@include respond-to('large') {
			padding-top: 25px;
		}
	}

}

.mat-form-field {
    width: 100% !important;

}

